import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginScreen from "../components/auth//LoginScreen";
import RegisterScreen from "../components/auth/RegisterScreen";
import HomePrivate from "../components/HomePrivate";
import HomePublic from "../components/HomePublic";
import NewNote from "../components/NewNote";
import NotaScreen from "../components/NotaScreen";
import Profile from "../components/Profile";

import { createClient } from "@supabase/supabase-js";
import { Provider } from "react-supabase";
import { firebase } from "../firebase/firebase-config";
import { login, startLogout } from "../redux/actions/auth";
import { setNotes, startLoadingNotes } from "../redux/actions/notes";
import { supabase } from "../supabase/client";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Home from "../components/Home";
import EditNote from "../components/EditNote";
import { Box, CircularProgress } from "@mui/material";
import Herramientas from "../components/Herramientas";
import Proyecto from "../components/Proyecto";
import Metodologia from "../components/Metodologia";
import Documentos from "../components/Documentos";

const client = createClient(
  "https://xyzcompany.supabase.co",
  "public-anon-key"
);

const AppRouter = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const [checking, setChecking] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [periodista, setPeriodista] = useState(false);
  // const [user, setUser] = useState(null);
  useEffect(() => {
    const consultarEstadoUsuario=async(id) => {
      const {data:data2,error:error2} = await supabase.from('periodistas').select('*').eq('idUsuario',id);
          if (data2.length>0 && error2===null) {
        //tiene el rol periodista

        setPeriodista(true);
      }else if (data2.length===0) {
        //no existe periodista, este usuario no es periodista
 
        setPeriodista(false);
      }
        
      
    }

    const { data: authListener } = supabase.auth.onAuthStateChange(async () => {
      const user = await supabase.auth.user();
      if (user) {
        // console.log(user)
        const {data:data2,error:error2} = await supabase.from('periodistas').select('*').eq('idUsuario',user.id);
        if (user.app_metadata?.providers.includes("google")) {
          //Consultado si existe ese usuario ya creado
          // const { data, error } = await supabase
          //   .from('usuarios')
          //   .select('*, usuarios!inner(*)')
          //   .eq('usuarios.idUsuario', user.id)
          //   console.log(data,'=>',error)
          // console.log('Se debe guardar');
          // const { data, error:err } = await supabase
          // .from('usuarios')
          // .insert([
          //   {
          //     idUsuario: user.id.toString(),
          //     tipoUsuario: 'autor',
          //     nombre: user.user_metadata.name,
          //     descripcion: "",
          //     correo: user.email.toString(),
          //     fotoUsuario: user.user_metadata.avatar_url
          //   }
          // ]);
        }
        if (user.user_metadata?.name) {
          dispatch(
            login(
              user.id,
              user.user_metadata?.name,
              user.user_metadata.avatar_url
            )
          );
        } else {
          dispatch(login(user.id, "Nombre", "default"));
        }
        if (data2.length>0 && error2===null) {
          //tiene el rol periodista
          setPeriodista(true);
        }else if (data2.length===0) {
          //no existe periodista, este usuario no es periodista
          setPeriodista(false);
        }
        setIsLoggedIn(true);
        // dispatch(startLoadingNotes());
      } else {
        setIsLoggedIn(false);
        setPeriodista(false)
        // dispatch(startLoadingNotes());
      }
      setChecking(false);
    });
    const user = supabase.auth.user();
    if (user) {
     consultarEstadoUsuario(user?.id);
      if (user.user_metadata?.name) {
        dispatch(
          login(
            user.id,
            user.user_metadata?.name,
            user.user_metadata?.avatar_url
          )
        );
      } else {
        dispatch(login(user.id, "Nombre", "default"));
      }
      
      setIsLoggedIn(true);
      // dispatch(startLoadingNotes());
      // dispatch(startLogout());
    } else {
      setIsLoggedIn(false);
      setPeriodista(false);
      // dispatch(startLoadingNotes());
    }
    setChecking(false);

    return () => {
      authListener?.unsubscribe();
      // console.log('se desuscribio')
    };
  }, [dispatch, setIsLoggedIn, setChecking]);

  // useEffect(() => {

  //   // firebase.auth().onAuthStateChanged(async (user) => {
  //   //   if (user?.uid) {
  //   //     dispatch(login(user.uid, user.displayName));
  //   //     setIsLoggedIn(true);
  //   //     // dispatch(startLoadingNotes(user.uid));
  //   //     console.log('Fetching');
  //   //     dispatch(startLoadingNotes());
  //   //   } else {
  //   //     setIsLoggedIn(false);
  //   //   }
  //   //   setChecking(false);
  //   // });

  //   // const getDatosBasicosUsuario = async(id) => {
  //   //   const {data,error} = supabase.from('usuarios').select('nombre,fotoUsuario').eq('idUsuario'.id);
  //   //   if (data.lengt===1) {
  //   //     return data[0];
  //   //   }else{
  //   //     return null
  //   //   }

  //   // }
  //   const user = supabase.auth.user();
  //   if(user!==null){
  //     //traer sus datos del usuario logeado

  //     if (auth?.uid) {
  //       console.log('hay usuario')
  //     }else{
  //     //   const usuario = getDatosBasicosUsuario(user.id);
  //     // dispatch(login(user.id, usuario.nombre));
  //       console.log('No hay usuario')
  //     }
  //     setIsLoggedIn(true);
  //     dispatch(startLoadingNotes());
  //   }else{
  //     setIsLoggedIn(false);
  //     dispatch(startLoadingNotes());
  //   }
  //   setChecking(false);
  //   // supabase.auth.onAuthStateChange((event, session) => {
  //   //   if (event == 'SIGNED_IN'){
  //   //     console.log('SIGNED_IN', session)
  //   //     dispatch(login(session.user.id, 'Algun nombre'));
  //   //     setIsLoggedIn(true);
  //   //     dispatch(startLoadingNotes());
  //   //   }else{
  //   //       setIsLoggedIn(false);
  //   //   }
  //   //   setChecking(false);
  //   // })
  // }, [dispatch, setChecking, setIsLoggedIn,auth.uid]);
  // const getDatosBasicosUsuario = async(id) => {
  //   const {data,error} = supabase.from('usuarios').select('nombre,fotoUsuario').eq('idUsuario'.id);
  //   if (data.lengt===1) {
  //     return data[0];
  //   }else{
  //     return null
  //   }

  // }
  if (checking) {
    return (
      <div style={{width:'100%',height:'600px',display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Box style={{ display: "flex", justifyContent: "center",alignItems:'center'}}>
        <CircularProgress variant="indeterminate" size={200} style={{ color: "#081d4b" }} />
      </Box>
      </div>
    );
  }
  return (
    <Provider value={client}>

      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<HomePublic />} /> */}
          <Route path="/notas/:idNota" element={<NotaScreen />} />
          <Route path="/notas" element={<HomePrivate />} />
          <Route path="/" element={<Home />} />
          <Route path="/herramientas" element={<Herramientas />} />
      <Route path="/proyecto" element={<Proyecto />} />
      <Route path="/metodologia" element={<Metodologia />} />
      <Route path="/documentos" element={<Documentos />} />
          <Route path="/profile/:idUsuario" element={<Profile />} />
          <Route element={<PrivateRoute isAuthenticated={isLoggedIn} isPeriodista={true} />}>
            <Route path="/newNote" element={<NewNote />} />
            <Route path="/editNote/:idNota" element={<EditNote />} />
            {/* <Route path='*' element={<h1>404</h1>}/> */}
          </Route>
          <Route element={<PublicRoute isAuthenticated={isLoggedIn} />}>
            <Route path="auth" element={<Navigate to="login" />} />
            <Route path="auth/login" element={<LoginScreen />} />
            {/* <Route path="auth/register" element={<RegisterScreen />} /> */}
            <Route path="*" element={<Navigate to="/auth/login" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};

export default AppRouter;
