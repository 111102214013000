import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import { getAnalytics } from "firebase/analytics";
import 'firebase/compat/firestore';

// import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyB_Wfcb0LMDK_ylltL_UyB66OeErK2gAz4",
  authDomain: "contratosabiertos-fa58f.firebaseapp.com",
  projectId: "contratosabiertos-fa58f",
  storageBucket: "contratosabiertos-fa58f.appspot.com",
  messagingSenderId: "794125101584",
  appId: "1:794125101584:web:4ec33e400bc5fc47c7f895",
  measurementId: "G-PTBQ4XD0N6"
};

// Initialize Firebase
const app= firebase.initializeApp(firebaseConfig);
 const analytics = getAnalytics(app);
const db = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export {
  db,
  googleAuthProvider,
  firebase
}