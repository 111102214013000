import React, { useMemo } from 'react'
import convCuces from '../../../data/conv_totales.json'
import items from '../../../data/items_conv.json'
import adjudicados from '../../../data/adjudicados.json'
import Container from '@mui/material/Container';
import { convCols, fichasCols, adjudicadosCols } from '../../../data/columns'
import Tabla from './Tabla';

const Tablas = () => {
  const dataTablas = useMemo(() => [
    {
      title: 'Datos de convocatorias',
      data: convCuces,
      columns: convCols,
      path: 'data/conv_totales.csv'
    },
    {
      title: 'Datos de adjudicados',
      data: adjudicados,
      columns: adjudicadosCols,
      path: 'data/adjudicados.csv'
    },
    {
      title: 'Datos de items',
      data: items,
      columns: fichasCols,
      path: 'data/items_conv.csv'
    }
  ], []);


  return (
    <Container maxWidth="lg">
      {dataTablas.map(({ title, data, columns, path }) => (
        <Tabla key={title} title={title} data={data} columns={columns} path={path} />
      ))}
    </Container>
  )
}

export default Tablas
