import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { startGoogleLogin, startLoginEmailPassword } from "../../redux/actions/auth";
import useForm from "../../hooks/useForm";
import Navbar from "../Navbar";
import MaterialUIDrawer from "../MaterialUIDrawer";
import TopNavigation from "../TopNavigation";
// import FacebookLogin from 'react-facebook-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import FacebookLogin from '@greatsumini/react-facebook-login';
const LoginScreen = () => {
 const dispatch = useDispatch();
 const {loading} = useSelector(state=>state.ui);
  // const [formValues,handleInputChange] = useForm({
  //   uid:'12',
  //   email: 'erick@gmail.com',
  //   password:"123456"
  // });
  const [formValues,handleInputChange] = useForm({
    uid:'',
    email: '',
    password:""
  });
  const {email,password} = formValues;
  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(startLoginEmailPassword(email,password));
  }
  // const responseFacebook = (response) => {
  //   console.log(response);
  // }
  // const handleClickFacebook =() => {
  //   console.log("Hola")
  // }
const handleGoogleLogin = () => {
  dispatch(startGoogleLogin());
}
// const isFormValid = ()=>{
//   if (!validator.isEmail(email)) {
//     dispatch(setError('Email is not valid'));
//     return false;
//   } 
//   return true;
// }
  return (
    <>
    {/* <Navbar /> */}
    {/* <MaterialUIDrawer /> */}
    <TopNavigation />
    <div className="auth__main">
      <div className="auth__box-container">
        <h3 className="auth__title font-face-cg">Iniciar sesión</h3>
        {/* <form onSubmit={handleLogin} className="animate__animated animate__fadeIn animate__faster">
          <input
            type="text"
            name="email"
            value={email}
            onChange={handleInputChange}
            className="auth__input font-face-arial"
            placeholder="Correo electrónico"
            autoComplete="off"
          />
          <input type="password" name="password"
          value={password}
          onChange={handleInputChange}
          className="auth__input font-face-arial" placeholder="Contraseña" />
          <button type="submit" disabled={loading}  className="btn btn-primary btn-block font-face-cg">Iniciar sesión</button> */}
          
          
          
          <div className="auth__social-networks font-face-arial">
            {/* <p>Inicia sesión con redes sociales</p> */}
            <div className="google-btn" onClick={handleGoogleLogin}>
              <div className="google-icon-wrapper">
                <img
                  className="google-icon"
                  src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                  alt="google button"
                />
              </div>
              <p className="btn-text font-face-arial">
                <b>Continuar con Google</b>
              </p>
            </div>
          </div>


            {/* <Link to="/auth/register" className="link font-face-arial">Registrate aquí</Link>
        </form> */}
        

{/* <FacebookLogin
    appId="542386851016007"
    autoLoad={false}
    fields="name,email,picture"
    onClick={handleClickFacebook}
    callback={responseFacebook}
    textButton=" Continuar con Facebook"
    icon="fa-facebook"
    cssClass="btn-facebook"
    /> */}
    {/* <FacebookLogin
  appId="542386851016007"
  onSuccess={(response) => {
    console.log('Login Success!', response);
  }}
  
  onFail={(error) => {
    console.log('Login Failed!', error);
  }}
  onProfileSuccess={(response) => {
    console.log('Get Profile Success!', response);
  }}
  
  className="btn-facebook"
  language="es_ES"
/> */}
      </div>
    </div>
    </>
  );
};

export default LoginScreen;
