import React, { useEffect, useState } from 'react'
import ArticleList from './ArticleList'
import {useSelector,useDispatch} from 'react-redux'
import { supabase } from '../supabase/client';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InfiniteScroll from 'react-infinite-scroll-component';
import { activeNote, setHasMore, setIndex, setNotes, startLoadingNotes } from '../redux/actions/notes';
import ArticlePreview from './ArticlePreview';
import './MainView.css';
const articles=[
	{
		"slug": "Create-a-new-implementation-1",
		"title": "Create a new implementation",
		"description": "join the community by creating a new implementation",
		"body": "Share your knowledge and enpower the community by creating a new implementation",
		"tagList": [
			"implementations"
		],
		"createdAt": "2021-11-24T12:11:08.212Z",
		"updatedAt": "2021-11-24T12:11:08.212Z",
		"favorited": false,
		"favoritesCount": 2932,
		"author": {
			"username": "Gerome",
			"bio": null,
			"image": "https://api.realworld.io/images/demo-avatar.png",
			"following": false
		}
	},
	{
		"slug": "Explore-implementations-1",
		"title": "Explore implementations",
		"description": "discover the implementations created by the RealWorld community",
		"body": "Over 100 implementations have been created using various languages, libraries, and frameworks.\n\nExplore them on CodebaseShow.",
		"tagList": [
			"codebaseShow",
			"implementations"
		],
		"createdAt": "2021-11-24T12:11:07.952Z",
		"updatedAt": "2021-11-24T12:11:07.952Z",
		"favorited": false,
		"favoritesCount": 1703,
		"author": {
			"username": "Gerome",
			"bio": null,
			"image": "https://api.realworld.io/images/demo-avatar.png",
			"following": false
		}
	},
	{
		"slug": "Welcome-to-RealWorld-project-1",
		"title": "Welcome to RealWorld project",
		"description": "Exemplary fullstack Medium.com clone powered by React, Angular, Node, Django, and many more",
		"body": "See how the exact same Medium.com clone (called Conduit) is built using different frontends and backends. Yes, you can mix and match them, because they all adhere to the same API spec",
		"tagList": [
			"welcome",
			"introduction"
		],
		"createdAt": "2021-11-24T12:11:07.557Z",
		"updatedAt": "2021-11-24T12:11:07.557Z",
		"favorited": false,
		"favoritesCount": 1198,
		"author": {
			"username": "Gerome",
			"bio": null,
			"image": "https://api.realworld.io/images/demo-avatar.png",
			"following": false
		}
	}
];
const MainView = () => {
	const dispatch = useDispatch();
	const {notes,hasMore,index} =useSelector(state=>state.notes);
	const [isLoading, setIsLoading] = useState(true);
	// const [state, setState] = useState({
  //   items: notes,
  //   hasMore: true
  // });
	// console.log('length ',notes.length);
	useEffect(() => {
		
			const todos = async() => {
		const { data, error } = await supabase
    .from('notas')
    .select(`
    *,
    usuario:idUsuario (nombre,fotoUsuario)
    `)
    .order('fechaHora', { ascending: false });
		dispatch(setNotes(data));
		setIsLoading(false);
	}
	todos();
    // dispatch(startLoadingNotes());
    // dispatch(getUser(idUsuario));
	}, [dispatch]);

	const fetchMoreData = () => {

    // if (notes.length >= 13) {
    //   // setState({ ...state,hasMore: false });
		// 	await dispatch(setHasMore(false));
    //   return;
    // }

			dispatch(startLoadingNotes());
    // setTimeout(() => {
    //   setState({...state,
    //     items: notes
    //   });
    // }, 1500);
		// setState({...state,items:notes});
  };
  return (
  	<div className="col-md-9">
			<div className="feed-toggle">
				<ul className="nav nav-pills outline-active">

					
						<a href="" className="nav-link active font-face-cg chip-all" style={{textDecoration:'none',fontWeight:'bolder'}}>
							Todas las notas
						</a>
					

				</ul>
			</div>
			{
				isLoading ? 
				<Box sx={{ display: 'flex',justifyContent:'center' }}>
				<CircularProgress sx={{color:'var(--primary-color)'}}/>
			</Box> :
				<ArticleList
       articles={notes} 
      />
			}
			
			
			{/* <InfiniteScroll
          dataLength={notes.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
					<Box sx={{ display: 'flex',justifyContent:'center' }}>
						<CircularProgress />
					</Box>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Ya no hay mas notas</b>
            </p>
          }
					style={{overflow:'hidden'}}
        >
          
          {
						notes.length===0 ?
						<div className="article-preview">
							No hay notas aún...
						</div> :

						<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
							{
								notes.map((i, index) => (
									// <div style={{height:'30px',margin:'6px',border:'1px solid green', padding:'8px'}} key={index}>
									//   div - #{index}
									// </div>
									<ArticlePreview key={i.idNota} article={i} />
									))
							}
						</div>
					}
        </InfiniteScroll> */}
		</div>
  )
}

export default MainView