// import React from 'react'
// import { Link } from 'react-router-dom'
// import './ArticlePreview.css'
// const ArticlePreview = ({article}) => {
// const {idNota,autor,fechaHora,titulo,contenido,tags,nFavs} = article

// return (
//     <div className="card">
// 				<img src={article.imgPrincipal} alt="" width="100%" height="250px" style={{objectFit:'cover',backgroundPosition:'center',backgroundSize:'cover',borderTopRightRadius:'3px',borderTopLeftRadius:'3px',marginBottom:'20px'}} />
// 			<div className="article-meta">
// 			<h2>{titulo}</h2>
// 				<Link to={`/notas/${idNota}`}>
// 					<img src={article.imgPrincipal} alt="" height="200px" />
// 				</Link>

// 				<div className="info">
// 					<Link className="author" to={`/notas/${idNota}`}>
// 					 {autor}
// 					</Link>
// 					<span className="date">
// 						{new Date(fechaHora).toDateString()}
// 					</span>
// 				</div>

// 				<div className="pull-xs-right">
// 					<button className="btn btn-sm btn-primary">
// 					<i className="fa-solid fa-heart"></i> {nFavs}
// 					</button>
// 				</div>
// 			</div>

// 			<Link to={`/notas/${idNota}`}
//       // {`article/${article.slug}`}
//        className="preview-link">
				
// 				<p className='text-cuted-content-article'>{contenido}</p>
// 				<span >Seguir leyendo...</span>
// 				<ul className="tag-list">
// 					{
// 						tags &&
// 						tags.map(tag => {
// 							return (
// 								<li key={tag} 
// 								className="tag-default tag-pill tag-outline"
                 
//                  >
// 									{tag}
// 								</li>
// 							)
// 						}
// 						)
// 					}
// 				</ul>
// 			</Link>
// 		</div>
//   )
// }

// export default ArticlePreview
import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import './ArticlePreview.css'
import Avatar from '@mui/material/Avatar';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { deepOrange, deepPurple } from '@mui/material/colors';
// import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions} from '@mui/material';
import { supabase } from '../supabase/client';
import { useSelector } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
const options = {year: 'numeric', month: 'long', day: 'numeric' }; 

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    [theme.breakpoints.down("md")] : {
    maxWidth: 200
    }
  },
  media: {
    height: 140
  }
}));
const ArticlePreview = ({article}) => {
	const navigate = useNavigate();
const {idNota,autor,fechaHora,titulo,contenido,tags,nFavs,imgPrincipal} = article
const {uid} = useSelector(state=>state.auth);
const [likes, setLikes] = useState(nFavs);
const [disabledLikeBtn, setDisabledLikeBtn] = useState(false);
const classes = useStyles();
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("sm"));
const handleNavigate=() => {
	navigate(`/notas/${idNota}`);

}
  var ele = document.createElement("div");
ele.innerHTML = contenido;
var image = ele.querySelector("img");
const handleLike = async() => {
  setDisabledLikeBtn(true);
  if (uid) {
    const {data:d,error:e} = await supabase.from('favs').select().eq('idUsuario',uid).eq('idNota',idNota);
  // console.log(d);
  // console.log(e);
  if (d.length>0) {
    //No se puede dar like, porque ya existe la row
    // console.log('Solo puedes dar like 1 vez');
    const { data:da, error:er } = await supabase
  .from('favs')
  .delete()
  .match({ idUsuario:uid,idNota:idNota });
    // console.log(da);
    // console.log(er);
  await supabase.rpc('vote', { 
    quote_id: idNota, increment_num: -1 
  });
  const {data:dat,error:err} = await supabase.from('notas').select().eq('idNota',idNota);
  setLikes(dat[0].nFavs)
  // console.log(dat);
  // console.debug(err);
  setDisabledLikeBtn(false);
    return;
  }
  const { data,error } = await supabase.from('favs')
    .insert([
       {
        idUsuario:uid,
        idNota:idNota 
       }
    ])
    .single();
    // console.log(data);
    // console.log(error);
   await supabase.rpc('vote', { 
    quote_id: idNota, increment_num: 1 
  });
  const {data:dat,error:err} = await supabase.from('notas').select().eq('idNota',idNota);
  // console.log(dat);
  // console.log(err);
  setLikes(dat[0].nFavs)
  setDisabledLikeBtn(false);
  }else{
    // console.log('se debe logear primero');
    setDisabledLikeBtn(false)
    navigate(`/auth/login`);
  }
  
}
// const handleUserProfile = () => {
//   navigate('/profile');
// }
if(image){

  // console.log(image);
}
return (
  <div className="card" onClick={handleNavigate}>
    <img src={imgPrincipal} alt="" style={{borderTopLeftRadius:'20px',borderTopRightRadius:'20px',width:'100%',height:'200px',objectFit:'cover',backgroundPosition:'center',backgroundSize:'cover'}} />
  <div className="container-card">
    <Typography gutterBottom variant="h4" component="div" sx={{fontFamily:'CenturyGothic',color:'var(--primary-color)'}}>   
      {titulo}    
    </Typography>
    <div className='font-face-arial' style={{display:'flex',alignItems:'center',marginTop:'5px',marginBottom:'5px',textAlign:'center'}}>
    {
          (article?.usuario?.fotoUsuario === 'default') ?
        //  <IconButton disabled  sx={{marginRight:'5px'}}>
          <PersonRoundedIcon sx={{marginRight:'5px'}}/>
          // </IconButton> 
          :
          // <IconButton disabled  sx={{marginRight:'5px'}}>
            <img src={article?.usuario?.fotoUsuario} alt="" style={{width:'22px',height:'22px',borderRadius:'50%',marginRight:'5px'}} referrerPolicy="no-referrer"/>
            // <Avatar alt="" src={article?.usuario?.fotoUsuario}  sx={{ width: 22, height: 22,marginRight:'5px'}} />
          // </IconButton>
        }
        por&nbsp;<span>{article?.usuario?.nombre}</span>&nbsp;el {new Date(fechaHora).toLocaleDateString('es-ES', options)} 
    </div>
    <Typography variant="body3" color="text.secondary" sx={{fontFamily:'Arial '}}>
      <div className='text-cuted-content-article' dangerouslySetInnerHTML={{__html: contenido}}>
        
        </div>
      </Typography>
  </div>
  <CardActions>
    {/* <Link to={`/notas/${idNota}`}>
      Leer más
    </Link> */}
    {/* <FavoriteBorderIcon sx={{width:'20px',height:'20px'}}/> */}
		<IconButton color="secondary" onClick={handleLike} disabled={disabledLikeBtn}>
    {/* <ThumbUpIcon fontSize='large' /> */}
    <StarIcon fontSize='large' sx={{color:'var(--accent-color)'}} /> &nbsp;{likes}
        {/* <AlarmIcon /> */}
      </IconButton>
  </CardActions>
 		</div>
  )
}

export default ArticlePreview