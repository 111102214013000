import * as React from "react"

const SvgLogoOscuro = (props) => (
  <svg
  width={props.width ? props.width : "120px"}
    height={props.height ? props.height : "40px"}
    viewBox="0 0 1304 355"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeMiterlimit: 1.5,
    }}
    {...props}
  >
    <g transform="matrix(.84244 0 0 .50458 -66.942 23.807)">
      <path
        d="m386 152 197 197-197 197-197-197 197-197Z"
        style={{
          fill: "#091d4b",
        }}
      />
      <clipPath id="a">
        <path d="m386 152 197 197-197 197-197-197 197-197Z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          style={{
            fill: "#fff",
          }}
          d="M348 120h328v328H348z"
          transform="matrix(.69522 0 0 .9037 30.046 159.471)"
        />
      </g>
      <path
        d="m386 152 197 197-197 197-197-197 197-197Z"
        style={{
          fill: "none",
          stroke: "#ebebeb",
          strokeWidth: "1.44px",
        }}
      />
    </g>
    <path
      d="m386 152 197 197-109.536 109.536-197-197L386 152Z"
      style={{
        fill: "#dfdfdf",
      }}
      transform="matrix(.84244 0 0 .50458 -232.902 123.21)"
    />
    <path
      d="m386 152 197 197-109.536 109.536-197-197L386 152Z"
      style={{
        fill: "#dfdfdf",
      }}
      transform="matrix(.84244 0 0 .50458 25.335 -31.462)"
    />
    <path
      d="M473.464 239.464 583 349 386 546 276.464 436.464l197-197Z"
      style={{
        fill: "#dfdfdf",
      }}
      transform="matrix(.84244 0 0 .50458 -232.902 -75.595)"
    />
    <path
      d="M531.25 296.812 583 349 386 546l-51.75-52.188 197-197Z"
      style={{
        fill: "#c4c4c4",
      }}
      transform="matrix(-.84244 0 0 .50458 747.413 -75.595)"
    />
    <path
      d="M531.25 296.812 583 349 386 546l-51.75-52.188 197-197Z"
      style={{
        fill: "#c4c4c4",
      }}
      transform="matrix(-.84244 0 0 .50458 539.82 50.14)"
    />
    <path
      d="M473.464 239.464 583 349 386 546 276.464 436.464l197-197Z"
      style={{
        fill: "#dfdfdf",
      }}
      transform="matrix(.84244 0 0 .50458 25.335 79.076)"
    />
    <path
      d="M531.25 296.812 583 349 386 546l-51.75-52.188 197-197Z"
      style={{
        fill: "#c4c4c4",
      }}
      transform="matrix(.84244 0 0 .50458 -23.347 50.14)"
    />
    <path
      d="M531.25 296.812 583 349 386 546l-51.75-52.188 197-197Z"
      style={{
        fill: "#c4c4c4",
      }}
      transform="matrix(.84244 0 0 .50458 -232.902 -75.595)"
    />
    <path
      style={{
        fill: "#fff",
      }}
      d="M348 120h328v328H348z"
      transform="matrix(.58568 0 0 .28486 -41.634 33.707)"
    />
    <path
      style={{
        fill: "#091d4b",
      }}
      d="M1927 1240h97v7h-97z"
      transform="matrix(1.29946 0 0 .97203 -2308.858 -1091.624)"
    />
    <path
      style={{
        fill: "#091d4b",
      }}
      d="M1927 1240h97v7h-97z"
      transform="matrix(1.29946 0 0 .97203 -2308.858 -1070.851)"
    />
    <path
      style={{
        fill: "#091d4b",
      }}
      d="M1927 1240h97v7h-97z"
      transform="matrix(1.29946 0 0 .97203 -2308.858 -1005.412)"
    />
    <path
      style={{
        fill: "#091d4b",
      }}
      d="M1927 1240h97v7h-97z"
      transform="matrix(1.29946 0 0 .97203 -2308.858 -1050.797)"
    />
    <path
      style={{
        fill: "#091d4b",
      }}
      d="M1927 1240h97v7h-97z"
      transform="matrix(1.29946 0 0 .97203 -2308.858 -985.358)"
    />
    <path
      d="m1867 1141 50 50"
      style={{
        fill: "none",
        stroke: "#e2706c",
        strokeWidth: "30.52px",
        strokeLinecap: "butt",
      }}
      transform="matrix(.46382 .1383 -.14258 .47814 -497.74 -790.686)"
    />
    <path
      d="m1867 1141 50 50"
      style={{
        fill: "none",
        stroke: "#7ccc96",
        strokeWidth: "30.52px",
        strokeLinecap: "butt",
      }}
      transform="matrix(-.4253 .23102 .23815 .43844 829.344 -919.803)"
    />
    <path
      d="M1969 1191v-50"
      style={{
        fill: "none",
        stroke: "#e8b54d",
        strokeWidth: "24.21px",
        strokeLinecap: "square",
      }}
      transform="matrix(.7685 0 0 .42094 -1254.015 -469.68)"
    />
    <path
      d="M.756-.585.7-.542a.321.321 0 0 0-.416-.082.29.29 0 0 0-.114.111.302.302 0 0 0-.04.156c0 .088.03.161.09.219a.31.31 0 0 0 .227.088A.32.32 0 0 0 .7-.168l.056.043a.35.35 0 0 1-.134.106.415.415 0 0 1-.179.037.373.373 0 0 1-.297-.125.38.38 0 0 1-.092-.256c0-.105.037-.194.111-.266a.384.384 0 0 1 .277-.107c.067 0 .127.013.182.039a.35.35 0 0 1 .132.112Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 655.555 156.623)"
    />
    <path
      d="M.328-.545a.276.276 0 0 1 .276.281.277.277 0 0 1-.077.194.26.26 0 0 1-.199.084.26.26 0 0 1-.2-.084.277.277 0 0 1-.077-.194.276.276 0 0 1 .277-.281Zm0 .067a.195.195 0 0 0-.146.063.21.21 0 0 0-.061.152c0 .039.009.075.027.108a.2.2 0 0 0 .18.104.21.21 0 0 0 .104-.027.206.206 0 0 0 .075-.077.223.223 0 0 0 .028-.108.21.21 0 0 0-.062-.152.194.194 0 0 0-.145-.063Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 768.301 156.623)"
    />
    <path
      d="M.076-.531h.069v.095a.287.287 0 0 1 .09-.082.229.229 0 0 1 .109-.027.187.187 0 0 1 .174.112.444.444 0 0 1 .022.16V0H.472v-.253a.623.623 0 0 0-.008-.123.135.135 0 0 0-.046-.079.136.136 0 0 0-.088-.027.171.171 0 0 0-.111.041.192.192 0 0 0-.065.102.756.756 0 0 0-.009.144V0H.076v-.531Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 858.268 156.623)"
    />
    <path
      d="M.136-.729h.069v.198h.108v.058H.205V0H.136v-.473H.042v-.058h.094v-.198Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 941.776 156.623)"
    />
    <path
      d="M.05-.531h.07v.077a.253.253 0 0 1 .066-.068.13.13 0 0 1 .072-.023c.019 0 .039.006.061.018l-.036.058a.099.099 0 0 0-.036-.01.098.098 0 0 0-.066.029.171.171 0 0 0-.048.087.79.79 0 0 0-.013.183V0H.05v-.531Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 986.247 156.623)"
    />
    <path
      d="M.605-.531V0H.538v-.091a.29.29 0 0 1-.096.078.258.258 0 0 1-.117.027.276.276 0 0 1-.273-.281c0-.077.027-.142.081-.196a.26.26 0 0 1 .194-.082c.043 0 .083.009.118.028a.269.269 0 0 1 .093.083v-.097h.067Zm-.273.052a.201.201 0 0 0-.105.028.218.218 0 0 0 0 .374.213.213 0 0 0 .288-.077.218.218 0 0 0 .027-.109.213.213 0 0 0-.06-.154.205.205 0 0 0-.15-.062Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1025.242 156.623)"
    />
    <path
      d="M.136-.729h.069v.198h.108v.058H.205V0H.136v-.473H.042v-.058h.094v-.198Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1119.29 156.623)"
    />
    <path
      d="M.328-.545a.276.276 0 0 1 .276.281.277.277 0 0 1-.077.194.26.26 0 0 1-.199.084.26.26 0 0 1-.2-.084.277.277 0 0 1-.077-.194.276.276 0 0 1 .277-.281Zm0 .067a.195.195 0 0 0-.146.063.21.21 0 0 0-.061.152c0 .039.009.075.027.108a.2.2 0 0 0 .18.104.21.21 0 0 0 .104-.027.206.206 0 0 0 .075-.077.223.223 0 0 0 .028-.108.21.21 0 0 0-.062-.152.194.194 0 0 0-.145-.063Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1163.77 156.623)"
    />
    <path
      d="m.343-.473-.044.045C.263-.463.227-.481.192-.481a.08.08 0 0 0-.057.022.07.07 0 0 0-.004.101.293.293 0 0 0 .082.056.305.305 0 0 1 .104.076.152.152 0 0 1-.019.194.155.155 0 0 1-.115.046.21.21 0 0 1-.088-.02.208.208 0 0 1-.069-.056L.069-.11c.035.039.072.059.111.059a.097.097 0 0 0 .07-.027.081.081 0 0 0 .01-.114.325.325 0 0 0-.086-.057.266.266 0 0 1-.098-.073.138.138 0 0 1-.026-.082.14.14 0 0 1 .146-.141c.049 0 .098.024.147.072Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1253.737 156.623)"
    />
    <path
      d="M.301-.718h.138L.716 0H.574L.518-.148H.225L.166 0H.024l.277-.718Zm.07.19-.096.247h.191L.371-.528Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 655.555 312.625)"
    />
    <path
      d="M.2-.736v.263a.226.226 0 0 1 .168-.072c.069 0 .128.026.176.079a.28.28 0 0 1 .073.201.28.28 0 0 1-.075.199.238.238 0 0 1-.264.063A.257.257 0 0 1 .2-.056V0H.067v-.736H.2Zm.14.314a.14.14 0 0 0-.105.043.158.158 0 0 0-.041.112c0 .048.014.086.041.115a.14.14 0 0 0 .105.044.135.135 0 0 0 .103-.045.16.16 0 0 0 .041-.113.153.153 0 0 0-.041-.112.134.134 0 0 0-.103-.044Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 770.14 312.625)"
    />
    <path
      d="M.12-.75c.023 0 .043.008.06.025a.084.084 0 0 1 .025.062.082.082 0 0 1-.025.061.083.083 0 0 1-.12-.001.084.084 0 0 1-.025-.062c0-.023.008-.043.025-.06A.079.079 0 0 1 .12-.75Zm-.067.219h.134V0H.053v-.531Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 873.268 312.625)"
    />
    <path
      d="M.6-.227H.171a.145.145 0 0 0 .05.09.157.157 0 0 0 .103.033c.05 0 .092-.017.128-.052l.112.053a.26.26 0 0 1-.1.088.316.316 0 0 1-.139.029.278.278 0 0 1-.204-.079.272.272 0 0 1-.079-.198.28.28 0 0 1 .079-.202.265.265 0 0 1 .196-.08c.084 0 .152.027.204.08A.29.29 0 0 1 .6-.252v.025ZM.466-.332a.13.13 0 0 0-.052-.072.148.148 0 0 0-.09-.028.16.16 0 0 0-.098.031.17.17 0 0 0-.049.069h.289Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 915.857 312.625)"
    />
    <path
      d="M.036-.531H.15v.067a.137.137 0 0 1 .05-.06.116.116 0 0 1 .067-.021c.017 0 .035.005.054.014L.28-.417a.106.106 0 0 0-.039-.011c-.02 0-.038.012-.052.038a.35.35 0 0 0-.021.149V0H.036v-.531Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1016.099 312.625)"
    />
    <path
      d="M.083-.727h.133v.196h.079v.114H.216V0H.083v-.417H.015v-.114h.068v-.196Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1070.22 312.625)"
    />
    <path
      d="M.317-.545c.05 0 .097.013.141.038a.275.275 0 0 1 .103.382.277.277 0 0 1-.244.139.263.263 0 0 1-.195-.082.268.268 0 0 1-.08-.197.27.27 0 0 1 .091-.208.267.267 0 0 1 .184-.072Zm.002.126a.135.135 0 0 0-.103.043.15.15 0 0 0-.042.11c0 .047.014.084.041.113a.139.139 0 0 0 .103.043.137.137 0 0 0 .104-.044.154.154 0 0 0 .042-.112.157.157 0 0 0-.041-.111.139.139 0 0 0-.104-.042Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1121.456 312.625)"
    />
    <path
      d="M.393-.457.31-.375C.277-.408.246-.424.219-.424a.058.058 0 0 0-.035.009c-.009.007-.013.014-.013.024 0 .007.003.014.008.02a.142.142 0 0 0 .04.024l.049.025a.298.298 0 0 1 .106.077.157.157 0 0 1 .028.093.151.151 0 0 1-.052.118.196.196 0 0 1-.139.048.224.224 0 0 1-.186-.091l.082-.09a.198.198 0 0 0 .055.045c.021.011.04.017.056.017a.07.07 0 0 0 .043-.013c.01-.009.016-.018.016-.029 0-.021-.02-.041-.058-.061L.174-.23C.088-.273.045-.327.045-.393c0-.042.016-.077.049-.107a.173.173 0 0 1 .124-.045.216.216 0 0 1 .175.088Z"
      style={{
        fill: "#fff",
        fillRule: "nonzero",
      }}
      transform="matrix(143.99981 0 0 143.99997 1221.698 312.625)"
    />
    <path
      style={{
        fill: "#e2706c",
      }}
      d="M2218 1181h28v132h-28z"
      transform="matrix(.8032 0 0 .8686 -1208.507 -1015.215)"
    />
    <path
      style={{
        fill: "#e8b54d",
      }}
      d="M2218 1181h28v132h-28z"
      transform="matrix(.8032 0 0 .8686 -1208.507 -900.559)"
    />
    <path
      style={{
        fill: "#7ccc96",
      }}
      d="M2218 1181h28v132h-28z"
      transform="matrix(.8032 0 0 .8686 -1208.507 -785.903)"
    />
  </svg>
)

export default SvgLogoOscuro
