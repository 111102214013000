import { fileUpload } from "../../helpers/fileUpload"
import { loadNotesProfile } from "../../helpers/loadNotesProfile"
import { loadNotesStarProfile } from "../../helpers/loadNotesStarProfile"
import { loadUserProfile } from "../../helpers/loadUserProfile"
import { supabase } from "../../supabase/client"
import { types } from "../../types/types"

export const activeProfile = (profile) => {
  return {
    type: types.profileActive,
    payload:profile===null ? null: {...profile}
  }
}
export const getUser = (id)=>{
  return async(dispatch,getState)=>{
    const usuario = await loadUserProfile(id);
    // console.log(usuario);
    if (usuario.length===1) {
      ///existe usuario
      dispatch(activeProfile(usuario[0]));
    }else{
      //no existe el usuario

    }
  }
}
export const startLoadingNotesProfile=(idUser) => {
  return async(dispatch,getState) => {
    const {index,notes:n,active} = getState().profile;
    // console.log(`Trayendo datos
    // , index>${index}, 
    // notes>${n}`)
    const notes = await loadNotesProfile(index,idUser);
    // console.log(`Desques de traer datos
    // , index>${index}, 
    // notes>${notes}`)
    if(notes.length>0){
      dispatch(setNotesProfile([...n,...notes]));
      dispatch(setIndexProfile(3));
    }else{
      dispatch(setNotesProfile([...n,...notes]));
      dispatch(setIndexProfile(3));
      dispatch(setHasMoreProfile(false))
    }
    
    
  }
}
export const setNotesProfile = (notes) => {
  return {
    type: types.profileLoadNotes,
    payload:notes
  }
}
export const setIndexProfile=(value) => {
  return {
    type:types.profileSetIndex,
    payload: value
  }
}
export const setHasMoreProfile=(value) => {
  return {
    type:types.profileSetHasMore,
    payload:value
  }
}
export const profileLogout = () => {
  return {
    type:types.profileLogoutCleaning
  }
}
export const startUploadingImageProfile = (file,uid) => {
  return async (dispatch,getState) => {
    // console.log('Uploading Image');
    const fileUrl = await fileUpload(file);
    // console.log("URL FILE","->",fileUrl);
    const fotoUri = await fileUrl;
    dispatch(startUpdateImageProfile(fotoUri,uid));
  }
} 
export const startUpdateImageProfile = (foto,id) => {
  return async (dispatch,getState) => {
    const {active} = getState().profile;
    const { data, error } = await supabase.from('usuarios')
    .update(
            {fotoUsuario: foto}
            )
    .match({ idUsuario: id })
    dispatch(activeProfile({...active,fotoUsuario:foto}));

  }
}
export const startUpdateDataProfile = (name,descripcion,id) => {
  return async (dispatch,getState) => {
    const {active} = getState().profile;
    const { data, error } = await supabase.from('usuarios')
    .update(
            {nombre: name,descripcion:descripcion}
            )
    .match({ idUsuario: id })
    dispatch(activeProfile({...active,nombre:name,descripcion:descripcion}));

  }
}
export const startLoadingNotesStarProfile=(idUser) => {
  return async(dispatch,getState) => {
    const {indexStar,notesStar:n,active} = getState().profile;
    const notes = await loadNotesStarProfile(indexStar,idUser);
    if(notes.length>0){
      dispatch(setNotesStarProfile([...n,...notes]));
      dispatch(setIndexStarProfile(3));
    }else{
      dispatch(setNotesStarProfile([...n,...notes]));
      dispatch(setIndexStarProfile(3));
      dispatch(setHasMoreStarProfile(false))
    }
    
    
  }
}
export const setNotesStarProfile = (notes) => {
  return {
    type: types.profileLoadNotesStar,
    payload:notes
  }
}
export const setIndexStarProfile=(value) => {
  return {
    type:types.profileSetIndexStar,
    payload: value
  }
}
export const setHasMoreStarProfile=(value) => {
  return {
    type:types.profileSetHasMoreStar,
    payload:value
  }
}