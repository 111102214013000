import React from 'react'
import ArticlePreview from './ArticlePreview';

const ArticleList = ({articles}) => {
  
  if(!articles) {
		return (
			<div className="article-preview">Cargando...</div>
		);
	}

	if(articles.length === 0) {
		return (
			<div className="article-preview font-face-arial" style={{fontSize:'1.2rem'}} >
				No hay notas aquí aún...
			</div>
		);
	}

	return (
		<div>
			{
				articles.map(article => (
					<ArticlePreview key={article.idNota} article={article} />
				))
			}
		</div>
	);
}

export default ArticleList