import React, { useEffect, useMemo, useState } from 'react'
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './NewNote.css'
import TextField from '@mui/material/TextField';
import { Editor } from '@tinymce/tinymce-react';
import { styled } from '@mui/material/styles';
import "easymde/dist/easymde.min.css";
import Navbar from './Navbar'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { activeNote, setHasMore, setIndex, setNotes, startUpdateNote, startUploading, startUploadingFromEdit } from '../redux/actions/notes';
import { supabase } from '../supabase/client';
import { activeProfile, setHasMoreProfile, setIndexProfile, setNotesProfile } from '../redux/actions/profile';
import { Box, CircularProgress } from '@mui/material';
import './EditNote.css'
import MaterialUIDrawer from './MaterialUIDrawer';
import TopNavigation from './TopNavigation';
const EditNote = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {idNota} = useParams();
  const {active,index,hasMore,notes} = useSelector(state=>state.notes);
  const [checking, setChecking] = useState(true);
  const getNotaById = (id) =>{
    return notes.find(nota=> nota.idNota===id);
  }
  const n = useMemo(() => getNotaById(idNota), [idNota]);
  // console.log(n)
  const [nota, setNota] = useState(n);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
    
  // const { titulo,
  //   contenido,
  //   tags } = nota;
    const editorRefEdit = useRef(null);
    useEffect(() => {
      if (!selectedFile) {
          setPreview(n?.imgPrincipal)
          return
      }
      
      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
      // console.log("object",objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile,n])
  // console.log('File',selectedFile);
  const onSelectFile = e => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    if (e.target.files[0]) {
       setSelectedFile(e.target.files[0])
       setNota({...nota,imgPrincipal:e.target.files[0]})
    }
    
}
function handleKeyDown(e){
  // e.preventDefault();
    if(e.key !== 'Enter') return
    e.preventDefault();
    const value = e.target.value
    if(!value.trim()) return
    setNota({
      ...nota,
      tags: [...nota.tags, value]
    })
    e.target.value = ''
}

function removeEtiqueta(index){
    setNota({...nota,
      tags: nota.tags.filter((el, i) => i !== index)  
    })
}
const log = () => {
  if (editorRefEdit.current) {
    // console.log(editorRef.current.getContent({format:'text'}));
    // console.log(editorRef.current.getContent());
    // console.log(active);
  }
};
const handleInputChange=(e) =>{
  setNota(() => ({ ...nota, [e.target.name]: e.target.value }))
}
const handleUpdateNote= async (e) => {
  setButtonDisabled(true);
  e.preventDefault();
  if (selectedFile===undefined) {
    await dispatch(startUpdateNote(nota));
  }else{
    await dispatch(startUploadingFromEdit(selectedFile,nota));
  }
  
  await  setNota({
    titulo:'',
    contenido:'',
    tags:[],
    imgPrincipal:{}
  });
  await  dispatch(activeNote(null))
    // console.log('listo');
    setButtonDisabled(false);
  dispatch(setNotes([]));
  dispatch(setIndex(index*(-1)));
  dispatch(setHasMore(true));

  dispatch(activeProfile(null));
  dispatch(setNotesProfile([]));
  dispatch(setIndexProfile(index*(-1)));
  dispatch(setHasMoreProfile(true));
  
  navigate('/notas',{ replace: true });
}
const onChangeEditor = (content) => {
  setNota(() => ({ ...nota, contenido: content}))
}
// useEffect(() => {
//   dispatch(activeNote({...nota}))
//   // console.log(selectedFile);
  
// }, [nota,dispatch]);
useEffect(() => {
  const getNote = async(idNote) => {
    const {data,error} = await supabase.from('notas').select(`
    *,
    usuario:idUsuario (nombre,fotoUsuario)
    `).eq('idNota',idNota);
    if (data.length===0) {
      // console.log('No se encontro esta nota, ya no existe');
      setChecking(false);
    }else{
      setNota(data[0]);
      dispatch(activeNote(data[0]));
      setChecking(false);
    }
    
  }

  // console.log(nota);
  if(nota===undefined){
    // console.log('es Undefined');
    //hacer peticion a supabase
    getNote(idNota);
  }else{
    // console.log('NO es undefined')
    setChecking(false);
    dispatch(activeNote(nota));
    // setNota(n);
  }
}, [nota,idNota,dispatch,setChecking]);
if (checking) {
  return <Box sx={{ display: 'flex',justifyContent:'center' }}>
  <CircularProgress sx={{color:'var(--primary-color)'}} />
</Box>;
}
if (nota===undefined) {
  return (
    <div>
      <TopNavigation />
       {/* <Navbar /> */}
       {/* <MaterialUIDrawer /> */}
      <h1 className='font-face-cg' style={{textAlign:'center'}}>Editando Nota</h1>
      <div className="container">
      
      <form  style={{display:'flex',flexDirection:'column',gap:'30px'}} onSubmit={handleUpdateNote}>
        {/* <input type="text" name="titulo" value={titulo} autoComplete="off" onChange={handleInputChange} className="form-control" placeholder="Titulo de la Nota"></input> */}
        <div style={{margin:'20px 0'}}>
            {
              !preview && <div style={{fontFamily:'Arial'}}>Seleccione una imagen para la portada</div>
            }
            <input id="getFile" type='file' accept="image/*" onChange={onSelectFile} disabled={buttonDisabled} style={{fontFamily:'Arial'}} />
            {preview &&  <img src={preview} alt="" width="80%" 
              style={{
                height:'250px',
                display:'block',
                textAlign:'center',
                marginTop:'20px',
                marginLeft:'auto',
                marginRight:'auto',
                borderRadius:'10px',
                objectFit:'cover'
                }}/> }
        </div>
        <TextField
          id="outlined-textarea"
          label="Título de la nota"
          placeholder="Titulo de la nota"
          multiline
          // sx={{fontSize:'24px'}}
          name="titulo"
          value={active.titulo}
          disabled={buttonDisabled}
          required={true}
          onChange={handleInputChange}
        />
        {/* <input type="text" name='tag' value={tag} autoComplete="off" onChange={handleInputChange} className="form-control" placeholder="Ingresa algunos tags o etiquetas"></input> */}
        <div className={`tags-input-container ${buttonDisabled ? "disabled-div" : ""}`} tabIndex="1">
            { active.tags.map((etiqueta, index) => (
                <div className="tag-item" key={index}>
                    <span className="text" style={{fontFamily:'Arial'}}>{etiqueta}</span>
                    <span className="close" style={{paddingBottom:'0px'}} onClick={() => removeEtiqueta(index)}>&times;</span>
                </div>  
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Escribe etiquetas y presiona Enter para crearlas" />
        </div>
        <TextField
          id="outlined-textarea"
          label="Link del recurso"
          placeholder="Pega aquí el link del recurso (opcional)"
          multiline
          name="link"
          value={active.link}
          disabled={buttonDisabled}
          required={false}
          onChange={handleInputChange}
        />
        <Editor
         apiKey='o6vn7duhwonkb0ofp3ps4k9p6c7t8utue9nzc7fpwmydpcit'
         onInit={(evt, editor) => editorRefEdit.current = editor}
         initialValue={{...active}.contenido}
         value={active.contenido}
         plugins='lists code image'
         disabled={buttonDisabled}
         init={{
           height: 500,
           
          selector:'textarea#file-picker',
          plugins:'image code preview fullscreen',
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | image | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help | fullscreen preview',
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = async function () {
              var file = this.files[0];
              const cloudUrl = 'https://api.cloudinary.com/v1_1/dewxeiala/image/upload';
            let formData = new FormData();
            formData.append('upload_preset','journal-react');
            formData.append("file", file);
      
            try {
              const resp = await fetch(cloudUrl,{method:'POST',body:formData});
              if(resp.ok){
                const cloudResp = await resp.json();
                // success(cloudResp.secure_url);
                cb(cloudResp.secure_url, { title: file.name });
              }else{
                throw await resp.json()
              }
            } catch (error) {
              // console.log("Error Image del Contenido: ",error);
              return;
            }
          };
          input.click();
        }
        ,
        image_list:[
          {title: 'My image 1', value: 'https://res.cloudinary.com/dewxeiala/image/upload/v1657289423/etyo6mvxkn7pqki6fcjb.png'},
          {title: 'My image 2', value: 'https://res.cloudinary.com/dewxeiala/image/upload/v1657243221/f3gxxafimcd07469gogk.png'}
         ]
         ,
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    
       }}
       onEditorChange={onChangeEditor}
       />
      <button id='publicar' disabled={buttonDisabled} type="submit" className="btn btn-primary btn-lg font-face-cg" style={{width:'200px',fontSize:'1rem'}}>Actualizar</button>
    </form>
       <button onClick={log}>Log editor content</button>
       <br />
       <Link to="/" id='goHome' style={{display:'none'}}>HOME</Link>
       </div>
    </div>
  )
}
  return (
    <div>
      <TopNavigation />
       {/* <Navbar /> */}
       {/* <MaterialUIDrawer /> */}
      <h1 className='font-face-cg' style={{textAlign:'center'}}>Editando Nota</h1>
      <div className="container">
      
      <form  style={{display:'flex',flexDirection:'column',gap:'30px'}} onSubmit={handleUpdateNote}>
        {/* <input type="text" name="titulo" value={titulo} autoComplete="off" onChange={handleInputChange} className="form-control" placeholder="Titulo de la Nota"></input> */}
        <div style={{margin:'20px 0'}}>
            {
              !preview && <div style={{fontFamily:'Arial'}}>Seleccione una imagen para la portada</div>
            }
            <input id="getFile" type='file' accept="image/*" onChange={onSelectFile} disabled={buttonDisabled} style={{fontFamily:'Arial'}} />
            {preview &&  <img src={preview} alt="" width="80%" 
              style={{
                height:'250px',
                display:'block',
                textAlign:'center',
                marginTop:'20px',
                marginLeft:'auto',
                marginRight:'auto',
                borderRadius:'10px',
                objectFit:'cover'
                }}/> }
        </div>
        <TextField
          id="outlined-textarea"
          label="Título de la nota"
          placeholder="Titulo de la nota"
          multiline
          name="titulo"
          value={nota.titulo}
          disabled={buttonDisabled}
          required={true}
          // sx={{fontSize:'24px'}}
          onChange={handleInputChange}
        />
        {/* <input type="text" name='tag' value={tag} autoComplete="off" onChange={handleInputChange} className="form-control" placeholder="Ingresa algunos tags o etiquetas"></input> */}
        <div className={`tags-input-container ${buttonDisabled ? "disabled-div" : ""}`} tabIndex="1">
            { nota?.tags.map((etiqueta, index) => (
                <div className="tag-item" key={index}>
                    <span className="text" style={{fontFamily:'Arial'}}>{etiqueta}</span>
                    <span className="close" style={{paddingBottom:'0px'}} onClick={() => removeEtiqueta(index)}>&times;</span>
                </div>  
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Escribe etiquetas y presiona Enter para crearlas" />
        </div>
        <TextField
          id="outlined-textarea"
          label="Link del recurso"
          placeholder="Pega aquí el link del recurso (opcional)"
          multiline
          name="link"
          value={active.link}
          disabled={buttonDisabled}
          required={false}
          onChange={handleInputChange}
        />
        <Editor
         apiKey='o6vn7duhwonkb0ofp3ps4k9p6c7t8utue9nzc7fpwmydpcit'
         onInit={(evt, editor) => editorRefEdit.current = editor}
         initialValue={n?.contenido}
         value={nota.contenido}
         plugins='lists code image'
         disabled={buttonDisabled}
         init={{
           height: 500,
           
          selector:'textarea#file-picker',
          plugins:'image code preview fullscreen',
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | image | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help | fullscreen preview',
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = async function () {
              var file = this.files[0];
              const cloudUrl = 'https://api.cloudinary.com/v1_1/dewxeiala/image/upload';
            let formData = new FormData();
            formData.append('upload_preset','journal-react');
            formData.append("file", file);
      
            try {
              const resp = await fetch(cloudUrl,{method:'POST',body:formData});
              if(resp.ok){
                const cloudResp = await resp.json();
                // success(cloudResp.secure_url);
                cb(cloudResp.secure_url, { title: file.name });
              }else{
                throw await resp.json()
              }
            } catch (error) {
              // console.log("Error Image del Contenido: ",error);
              return;
            }
          };
          input.click();
        }
        ,
        image_list:[
          {title: 'My image 1', value: 'https://res.cloudinary.com/dewxeiala/image/upload/v1657289423/etyo6mvxkn7pqki6fcjb.png'},
          {title: 'My image 2', value: 'https://res.cloudinary.com/dewxeiala/image/upload/v1657243221/f3gxxafimcd07469gogk.png'}
         ]
         ,
         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    
       }}
       onEditorChange={onChangeEditor}
       />
      <button id='publicar' disabled={buttonDisabled} type="submit" className="btn btn-primary btn-lg font-face-cg" style={{width:'200px',fontSize:'1rem',borderRadius:'20px'}}>Actualizar</button>
    </form>
       {/* <button onClick={log}>Log editor content</button> */}
       <br />
       <Link to="/" id='goHome' style={{display:'none'}}>HOME</Link>
       </div>
    </div>
  )
}

export default EditNote