export const types = {
  login: "[Auth] login",
  logout: "[Auth] logout",

  uiSetError: "[UI] setError",
  uiRemoveError: "[UI] removeError",
  uiStartLoading: "[UI] Start loading",
  uiFinishLoading: "[UI] Finish loading",

  notesAddNew: '[Notes] New note',
  notesActive: '[Notes] Set active note',
  notesLoad: '[Notes] Load notes',
  // notesLoadSingle: '[Notes] Load note single',
  notesUpdated: '[Notes] Updated note',
  notesFileUrl: '[Notes] Updated image url',
  notesDelete: '[Notes] Delete note',
  notesLogoutCleaning:'[Notes] Logout Cleaning',
  notesSetIndex: '[Notes] Set index',
  notesSetHasMore: '[Notes] SetHasMore',
  notesResetIndex: '[Notes] Reset Index',

  profileLoadSingle: '[Profile] Load Profile',
  profileActive: '[Profile] Set active Profile',
  profileUpdated:'[Profile] Updated profile',
  profileLoadNotes: '[Profile] Load profile notes',
  profileSetIndex: '[Profile] Set Index Profile',
  profileSetHasMore: '[Profile] Set has more Profile',
  profileLogoutCleaning:'[Profile] Cleanig Progile log out',
  profileLoadNotesStar: '[Profile] Load profile notes Star',
  profileSetIndexStar: '[Profile] Set Index Profile Star',
  profileSetHasMoreStar: '[Profile] Set has more Profile Star',
};
