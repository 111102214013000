import React, { useState, useMemo } from "react";
import GraficosOther from "./GraficosOther";
import GraficosOther2 from "./GraficosOther2";
import Select from "@mui/material/Select";
import Tabla2 from "./Tabla2";
import Papa from "papaparse";
import { Box, FormControl } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import StorageIcon from "@mui/icons-material/Storage";
import Button from "@mui/material/Button";
import Typography from '@mui/material/Typography';

const apiConv =
  "https://convocatorias-sicoes.fly.dev/convocatorias.csv?sql=SELECT+*+FROM+datos%0D%0AWHERE+";
const apiItems =
  "https://contratos-sicoes.fly.dev/contratos.csv?sql=SELECT+*+FROM+contratos%0D%0AWHERE+";
const queryDate = "strftime%28%27%25Y-%25m%27%2C+fecha_publicacion%29+%3D+%27";
const queryDate2 = "strftime%28%27%25Y-%25m%27%2C+Fecha_Publicación%29+%3D+%27";
const queryDepartamento = "departamento+COLLATE+NOCASE+";
const queryEstado = "%27%0D%0A++AND+estado+COLLATE+NOCASE+";
const atribuciones = [
  { name: "Mauricio Foronda", url: "https://github.com/mauforonda" },
  { name: "Eduardo Arraya", url: "https://github.com/pr0nstar" },
];

const yearList = {
  2012: "2012",
  2013: "2013",
  2014: "2014",
  2015: "2015",
  2016: "2016",
  2017: "2017",
  2018: "2018",
  2019: "2019",
  2020: "2020",
  2021: "2021",
  2022: "2022",
};

const monthList = {
  Enero: "01",
  Febrero: "02",
  Marzo: "03",
  Abril: "04",
  Mayo: "05",
  Junio: "06",
  Julio: "07",
  Agosto: "08",
  Septiembre: "09",
  Octubre: "10",
  Noviembre: "11",
  Diciembre: "12",
};

const departamentoList = {
  "NOT+NULL": "Sin Definir",
  "%3D+%27la+paz%27": "La Paz",
  "%3D+%27santa+cruz%27": "Santa Cruz",
  "%3D+%27tarija%27": "Tarija",
  "%3D+%27cochabamba%27": "Cochabamba",
  "%3D+%27chuquisaca%27": "Chuquisaca",
  "%3D+%27potosi%27": "Potosi",
  "%3D+%27oruro%27": "Oruro",
  "%3D+%27beni%27": "Beni",
  "%3D+%27pando%27": "Pando",
};
const estadoList = {
  "NOT+NULL": "Sin Definir",
  "%3D+%27contratado%27": "Contratado",
  "%3D+%27sin+documentacion+de+cierre%27": "Sin documentacion de cierre",
  "%3D+%27cancelado%27": "Cancelado",
  "%3D+%27anulado+desde+la+convocatoria%27": "Anulado desde la convocatoria",
  "%3D+%27en+curso%27": "En curso",
  "%3D+%27eliminado+(registro+erroneo)%27": "Eliminado (registro erroneo)",
  "%3D+%27desierto%27": "Desierto",
  "%3D+%27adjudicado%27": "Adjudicado",
  "%3D+%27suspendido%27": "Suspendido",
  "%3D+%27vigente%27": "Vigente",
};

const colsCov = [
  {
    header: "CUCE",
    accessorKey: "CUCE",
  },
  {
    header: "Fecha de publicación",
    accessorKey: "Fecha_Publicación",
  },
  {
    header: "Departamento",
    accessorKey: "Departamento",
  },
  {
    header: "Estado",
    accessorKey: "Estado",
  },
  {
    header: "Entidad",
    accessorKey: "Entidad",
  },
  {
    header: "Tipo de contratación",
    accessorKey: "Tipo_de_Contratación",
  },
  {
    header: "Modalidad",
    accessorKey: "Modalidad",
  },
  {
    header: "Objeto",
    accessorKey: "Objeto_de_Contratación",
  },
];

const colsItems = [
  {
    header: "CUCE",
    accessorKey: "cuce",
  },
  {
    header: "Fecha de publicación",
    accessorKey: "fecha_publicacion",
  },
  {
    header: "Departamento",
    accessorKey: "departamento",
  },
  {
    header: "Estado",
    accessorKey: "estado",
  },

  {
    header: "Cantidad",
    accessorKey: "cantidad",
  },
  {
    header: "Precio unitario",
    accessorKey: "precio_unitario",
  },
  {
    header: "Precio total",
    accessorKey: "precio_total",
  },
  {
    header: "Unidad",
    accessorKey: "unidad_de_medida",
  },
  {
    header: "Nombre o razón social",
    accessorKey: "nombre_o_razon_social",
  },
  {
    header: "Entidad",
    accessorKey: "entidad",
  },
  {
    header: "Descripción",
    accessorKey: "descripcion",
  },
];

const OtherPage = () => {
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [date, setDate] = useState("201201");
  const [department, setDepartment] = useState("NOT+NULL");
  const [estados, setestados] = useState("NOT+NULL");

  const columnsConv = useMemo(() => colsCov, []);
  const columnsItems = useMemo(() => colsItems, []);

  const getData = async () => {
    const url =
      apiConv +
      queryDepartamento +
      department +
      "%0D%0A++AND+" +
      queryDate2 +
      date.substring(0, 4) +
      "-" +
      date.substring(4, 7) +
      queryEstado +
      estados +
      "%3B";
    console.log("conv ", url);
    Papa.parse(url, {
      download: true,
      header: true,
      complete: function (results) {
        setData(results.data);
      },
    });
  };
  const getItems = async () => {
    const url =
      apiItems +
      queryDepartamento +
      department +
      "%0D%0A++AND+" +
      queryDate +
      date.substring(0, 4) +
      "-" +
      date.substring(4, 7) +
      queryEstado +
      estados +
      "%3B";
    console.log("cont ", url);
    Papa.parse(url, {
      download: true,
      header: true,
      complete: function (results) {
        setItems(results.data);
      },
    });
  };
  // get data from API
  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
    getItems();
  };

  const handleChange = (e) => {
    setDate(e.target.value);
  };

  const handleChangeForDepartment = (e) => {
    setDepartment(e.target.value);
  };
  const handleChangeForEstado = (e) => {
    setestados(e.target.value);
  };

  return (
    <div>
      <GraficosOther />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={3}
      >
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ margin: "20px" }}>
            <Select native defaultValue="" onChange={handleChange}>
              {Object.keys(yearList).map((year) => (
                <optgroup label={year}>
                  {Object.keys(monthList).map((month) => (
                    <option value={year + monthList[month]}>{month}</option>
                  ))}
                </optgroup>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ margin: "20px" }}>
            <Select native defaultValue="" onChange={handleChangeForDepartment}>
              {Object.keys(departamentoList).map((key) => (
                <option value={key}>{departamentoList[key]}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ margin: "20px" }}>
            <Select native defaultValue="" onChange={handleChangeForEstado}>
              {Object.keys(estadoList).map((key) => (
                <option value={key}>{estadoList[key]}</option>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{ height: "56px", margin: "20px" }}
            type="submit"
            variant="contained"
          >
            Buscar
          </Button>
        </form>
      </Box>

      <Tabla2
        data={data}
        columns={columnsConv}
        title="Convocatorias"
        atrName={atribuciones[0].name}
        atrUrl={atribuciones[0].url}
      />

      <GraficosOther2 />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom={3}
      >
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ margin: "20px" }}>
            <Select native defaultValue="" onChange={handleChange}>
              {Object.keys(yearList).map((year) => (
                <optgroup label={year}>
                  {Object.keys(monthList).map((month) => (
                    <option value={year + monthList[month]}>{month}</option>
                  ))}
                </optgroup>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ margin: "20px" }}>
            <Select native defaultValue="" onChange={handleChangeForDepartment}>
              {Object.keys(departamentoList).map((key) => (
                <option value={key}>{departamentoList[key]}</option>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ margin: "20px" }}>
            <Select native defaultValue="" onChange={handleChangeForEstado}>
              {Object.keys(estadoList).map((key) => (
                <option value={key}>{estadoList[key]}</option>
              ))}
            </Select>
          </FormControl>
          <Button
            sx={{ height: "56px", margin: "20px" }}
            type="submit"
            variant="contained"
          >
            Buscar
          </Button>
        </form>
      </Box>
      <Tabla2
        data={items}
        columns={columnsItems}
        title="Items"
        atrName={atribuciones[1].name}
        atrUrl={atribuciones[1].url}
      />

      
<Container maxWidth="lg">
<Typography
        variant="h4"
        align="center"
        padding="20px"
        sx={{ fontWeight: "bold ", color: "#081d4b" }}
      >
        Consultar en la Base de Datos
      </Typography>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "0 50px",
        }}
      >
        <Link
          sx={{
            textDecoration: "none",
            border: "3px",

          }}
          href="https://convocatorias-sicoes.fly.dev/convocatorias"
        >
          <Button
            sx={{ height: "56px", margin: "10px", width:"260px" }}
            variant="contained"
            startIcon={<StorageIcon />}
          >
            Consultar Convocatorias
          </Button>
        </Link>
        <Link
          sx={{
            textDecoration: "none",

            border: "3px",

          }}
          href="https://contratos-sicoes.fly.dev/contratos"
        >
          <Button
            sx={{ height: "56px", margin: "10px", width:"260px" }}
            variant="contained"
            startIcon={<StorageIcon />}
          >
            Consultar Items (contratos)
          </Button>
        </Link>
      </Container>
      </Container>
    </div>
  );
};

export default OtherPage;

// https://contratos-sicoes.fly.dev/contratos?sql=SELECT+*+FROM+contratos%0D%0AWHERE+departamento+IS+NOT+NULL%0D%0A++AND+strftime%28%27%25Y-%25m%27%2C+fecha_publicacion%29+IS+NOT+NULL%0D%0A++AND+estado+IS+NOT+NULL%3B%0D%0A%0D%0A%0D%0A%0D%0A
// https://contratos-sicoes.fly.dev/contratos?sql=SELECT+*+FROM+contratos%0D%0AWHERE+departamento+%3D+%27pando%27%0D%0A++AND+strftime%28%27%25Y-%25m%27%2C+fecha_publicacion%29+%3D+%272014-01%27%0D%0A++AND+estado+%3D+%27contratado%27%3B%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A
