import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import { tablaES } from '../../../data/tabla_es';


export default function Tabla2({ columns, data, title, atrName, atrUrl}) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#081D4B',
            }
        },
    }, esES);
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };
    return (
        <ThemeProvider theme={theme} >


            <MaterialReactTable 
                columns={columns}
                data={data}
                localization={tablaES}
                initialState={{density: 'compact'}}
                renderToolbarTopCustomActions={({ table }) => (
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                    >
                        Descargar resultados
                    </Button>
                )}

            />
            <Box sx={{ textAlign: 'right' }}>
                <Typography variant="caption" gutterBottom align="right" padding="20px">
                    Recopilación: <a href={atrUrl} target="_blank">{atrName}</a>
                </Typography>
            </Box>
        </ThemeProvider>
    )
}
