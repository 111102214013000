import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { authReducer } from "../reducers/authReducer";
import { notesReducer } from "../reducers/notesReducer";
import { profileReducer } from "../reducers/profileReducer";
import { uiReducer } from "../reducers/uiReducer";


const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const reducers = combineReducers({
  auth: authReducer,
  ui:uiReducer,
  notes:notesReducer,
  profile: profileReducer
});
export const store = createStore(reducers
  ,
 composeEnhancers(applyMiddleware(thunk)));