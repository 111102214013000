import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import PreviewCard from "./PreviewCard";

const LinkPreview=({linkUrl=''})=> {
    // const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
   
  //   const handleSubmit = async(evt) => {
  //     setLoading(true)
  //     evt.preventDefault();
  //     if (text!=='') {
  //       try {
  //         const res = await fetch('http://localhost:3003/', { 
  //         method: 'POST', 
  //         // mode:'no-cors',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({ urlPeticion:text }) 
  //       });
  //     const data = await res.json();
  //     if (data.ok) {
  //       setLinks([data.data]);
  //       setLoading(false);
  //     }else{
  //       console.log('Respuesta NO OK');
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log('Ocurrio un error:', error.message);
  //     setLoading(false);
  //   }
  //   }else{
  //   console.log('El campo esta vacio')
  //   setLoading(false);
  //   } 
  // }
    useEffect(() => {
      // console.log(linkUrl)
      setLoading(true);
      const traerLink = async() => {
        if (linkUrl!=='') {
            
          try {
            const res = await fetch('https://us-central1-contratosabiertos-fa58f.cloudfunctions.net/widgets/', { 
            method: 'POST', 
            // mode:'no-cors',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ urlPeticion:linkUrl }) 
          });
        const data = await res.json();
        if (data.ok) {
          // console.log(data.data);
          setLinks([data.data]);
          setLoading(false);
        }else{
          console.log('Respuesta NO OK');
          setLoading(false);
        }
      } catch (error) {
        console.log('Ocurrio un error:', error.message);
        setLoading(false);
      }
      }else{
      // console.log('El campo esta vacio')
      setLoading(false);
      }
      }
      traerLink();
    }, [linkUrl]);
   
  return (
    <div>
      {/* <h1>Form</h1>
      Try this: <pre>some example text https://fireship.io and https://fireship.io/courses/javascript/</pre>

      <form onSubmit={handleSubmit}>
        <textarea rows="4" cols="50" 
          type="text" 
          value={text}
          onChange={e => setText(e.target.value)}>

        </textarea>
        <br />
        <input type="submit" value="Submit" />
        </form> */}

        {/* <h2>Preview</h2> */}
        {/* <p>{text}</p> */}

        {loading &&  	<Box sx={{ display: 'flex',justifyContent:'center' }}>
				<CircularProgress sx={{color:'var(--primary-color)'}}/>
			</Box> }


        { links.map(obj => <PreviewCard key={obj.url} linkData={obj} />) }


    </div>
  )
}
export default LinkPreview;