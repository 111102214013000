import { types } from "../../types/types";
const initialState = {
  notes: [],
  active: null,
  index:0,
  hasMore:true,
  notesStar:[],
  indexStar:0,
  hasMoreStar:true
}
export const profileReducer = (state=initialState,action) => {
  switch (action.type) {
    case types.profileActive:
      return {
        ...state,
        active: action.payload===null ? null : {...action.payload}
      }  
    case types.profileLoadNotes:
      return{
        ...state,
        notes: [...action.payload]
      }
      case types.profileSetIndex:
        return {
          ...state,
          index:state.index+action.payload
        }
      case types.profileSetHasMore:
        return {
          ...state,
          hasMore: action.payload
        }
      case types.profileLoadNotesStar:
        return{
          ...state,
          notesStar: [...action.payload]
        }
        case types.profileSetIndexStar:
          return {
            ...state,
            indexStar:state.indexStar+action.payload
          }
        case types.profileSetHasMoreStar:
          return {
            ...state,
            hasMoreStar: action.payload
          }
      case types.profileLogoutCleaning:
        return {
          ...state,
          active:null,
          notes:[],
          index:0,
          hasMore:true,
          notesStar:[],
          indexStar:0,
          hasMoreStar:true
        }
    default:
      return state;
  }
}

