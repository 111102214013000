import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { activeNote, setHasMore, setIndex, setNotes, startLoadingNotesWithTag } from '../redux/actions/notes';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArticlePreview from './ArticlePreview';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ArticleList from './ArticleList';
const FilteredNotes = ({notasFiltradas,isLoading}) => {
	const dispatch = useDispatch();
  // const {notes,hasMore,index} =useSelector(state=>state.notes);
	// const [state, setState] = useState({
  //   items: notes,
  //   hasMore: true
  // });
	// console.log('length ',notes.length);
	// useEffect(() => {
	// 	dispatch(activeNote(null));
  //   dispatch(setNotes([]));
  //   dispatch(setHasMore(true));
  //   dispatch(setIndex(index*(-1)));
  //   dispatch(startLoadingNotesWithTag(t));
  //   // dispatch(getUser(idUsuario));
	// }, [dispatch]);
	// const fetchMoreDataWithTag = async() => {

	// 	await	dispatch(startLoadingNotesWithTag(t));

  // };
  return (
    <div className="col-md-9">
			<div className="feed-toggle">
				<ul className="nav nav-pills outline-active">

					{/* <li className="nav-item"> */}
						<a href="" className="nav-link active font-face-cg" style={{textDecoration:'none',fontWeight:'bolder'}}>
							Todas las notas
						</a>
					{/* </li> */}

				</ul>
			</div>
			{/* <ArticleList
       articles={notes} 
      /> */}
				{
				isLoading ? 
				<Box sx={{ display: 'flex',justifyContent:'center' }}>
				<CircularProgress />
			</Box> :
				<ArticleList
       articles={notasFiltradas} 
      />
			}
			{/* <InfiniteScroll
          dataLength={notes.length}
          next={fetchMoreDataWithTag}
          hasMore={hasMore}
          loader={
					<Box sx={{ display: 'flex',justifyContent:'center' }}>
						<CircularProgress />
					</Box>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Ya no hay mas notas</b>
            </p>
          }
					style={{overflow:'hidden'}}
        >
          
          {
						notes.length===0 ?
						<div className="article-preview">
							No hay notas aún...
						</div> :

						<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
							{
								notes.map((i, index) => (
									// <div style={{height:'30px',margin:'6px',border:'1px solid green', padding:'8px'}} key={index}>
									//   div - #{index}
									// </div>
									<ArticlePreview key={i.idNota} article={i} />
									))
							}
						</div>
					}
        </InfiniteScroll> */}
		</div>
  )
}

export default FilteredNotes