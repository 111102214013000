import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { BarChart, Bar } from 'recharts';
import serieTiempo from '../../../data/serie_tiempo.json'
import frecTemas from '../../../data/frec_temas.json'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Graficos = () => {
  return (
    <div>
      <Typography variant="h5" align="center" sx={{fontWeight:"bold ", color:"#081d4b" }}>
        Contratos sobre Covid-19
      </Typography>
      <Typography variant="body1" align="center" gutterBottom> (de marzo de 2020 a enero de 2022)</Typography>

      <Grid container item spacing={2} display="flex" align="center">
        <Grid item xs={12}>
        <Typography variant="subtitle1" gutterBottom  align="center">
            Cantidad de contratos por mes
      </Typography>
          <ResponsiveContainer width="60%" height={300}>
          <LineChart   data={serieTiempo} fontFamily={'Roboto, sans-serif'} >
            <Line 
            type="monotone" 
            dataKey="n_conv" 
            stroke="#081D4B" 
            strokeWidth={4} 
            animationBegin={1000}
            animationDuration={3000}
            />
            <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
            <XAxis dataKey="mes"  />
            <YAxis  />
            <Tooltip />
          </LineChart>
          </ResponsiveContainer>
   
         
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom  align="center">
            Temas de los objetos de los contratos
          </Typography>
          <ResponsiveContainer width="60%" height={300}>

          <BarChart  data={frecTemas} fontFamily={'Roboto, sans-serif'} >
            <XAxis dataKey="tema" />
            <YAxis />
            <Bar dataKey="n" barSize={30} fill="#081D4B" 
            animationBegin={2000}
            animationDuration={3000}/>
            <Tooltip />
          </BarChart>
          </ResponsiveContainer>

          
        </Grid>
      </Grid>
    </div>
  )
}

export default Graficos