import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useForm from '../hooks/useForm';
import { activeNote, resetIndexNotes, setHasMore, setIndex, setNotes, startLoadingNotes, startNewNote, startSaveNote, startUploading } from '../redux/actions/notes';
import Navbar from './Navbar'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import "easymde/dist/easymde.min.css";
import { Editor } from '@tinymce/tinymce-react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import './NewNote.css'
import TextField from '@mui/material/TextField';
import { supabase } from '../supabase/client';
import { activeProfile, setHasMoreProfile, setIndexProfile, setNotesProfile } from '../redux/actions/profile';
import MaterialUIDrawer from './MaterialUIDrawer';
import TopNavigation from './TopNavigation';
const initialState ={
    titulo:'',
    contenido:'',
    tags:[],
    imgPrincipal:{}
  };
  type ImageUploadType = {
    (image: File, 
    onSuccess: (url: string) => void, 
    onError: (errorMessage: string) => void) : void
 }
 const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
const NewNote = () => {
  // const [value, setValue] = React.useState("**Hello world!!!**");
  // const [value, setValue] = useState("Initial value");
  // const [chipData, setChipData] = useState([
  //   { key: 0, label: 'Angular' },
  //   { key: 1, label: 'jQuery' },
  //   { key: 2, label: 'Polymer' },
  //   { key: 3, label: 'React' },
  //   { key: 4, label: 'Vue.js' },
  // ]);
  // const [tags, setTags] = useState([])
  
  // const onChange = useCallback((value: string) => {
  //   setValue(value);
  // }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {active,index,hasMore} = useSelector(state=>state.notes);
  const [nota, setNota] = useState(initialState);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
    
  const { titulo,
    contenido,
    tags ,link} = nota;
    // const [etiquetas, setEtiquetas] = useState(initialState.tags);
    const editorRef = useRef(null);
    // console.log("SelectedFile",selectedFile);
    // console.log("preview",preview);
    useEffect(() => {
      if (!selectedFile) {
          setPreview(undefined)
          return
      }
      
      const objectUrl = URL.createObjectURL(selectedFile)
      setPreview(objectUrl)
      // console.log("object",objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])
  const onSelectFile = e => {
    e.preventDefault();
    if (!e.target.files || e.target.files.length === 0) {
        setSelectedFile(undefined)
        return
    }

    // I've kept this example simple by using the first image instead of multiple
    if (e.target.files[0]) {
       setSelectedFile(e.target.files[0])
       setNota({...nota,imgPrincipal:e.target.files[0]})
    }
    
}
    function handleKeyDown(e){
      // e.preventDefault();
        if(e.key !== 'Enter') return
        e.preventDefault();
        const value = e.target.value
        if(!value.trim()) return
        setNota({
          ...nota,
          tags: [...nota.tags, value]
        })
        e.target.value = ''
    }

    function removeEtiqueta(index){
        setNota({...nota,
          tags: nota.tags.filter((el, i) => i !== index)  
        })
    }
  
    // const handleDelete = (chipToDelete) => () => {
    //   setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    // };  
    const log = () => {
      if (editorRef.current) {
        // console.log(editorRef.current.getContent({format:'text'}));
        // console.log(editorRef.current.getContent());
        // console.log(active);
      }
    };
    const imageUpload:ImageUploadType  = async (image, onSuccess, onError) => {
      const cloudUrl = 'https://api.cloudinary.com/v1_1/dewxeiala/image/upload';
      const data = new FormData();
      data.append('file', image);
      // replace 'giftea' with your upload preset name, don't use mine lol
      data.append('upload_preset', 'journal-react');
      // in the cloudinary end point, replace 'gifteacloud' with your cloudinary name
      // const res = await axios.post('https://api.cloudinary.com/v1_1/gifteacloud/image/upload', data);
    try {
        const res = await fetch(cloudUrl,{method:'POST',body:data});
      if(res.ok){
        const cloudResp = await res.json();
        onSuccess(cloudResp.secure_url) ;
      }else{
        throw await res.json()
      }
    } catch (error) {    
        console.error(error)
    }
  }
  
  const handleInputChange=(e) =>{
    setNota(() => ({ ...nota, [e.target.name]: e.target.value }))
  }
  // const disabledBtn=()=>{
  //   setButtonDisabled(true);
  // }
  // const enabledBtn=()=>{
  //   setButtonDisabled(false);
  // }
  const handlePublish = async (e) => {
    setButtonDisabled(true);
    e.preventDefault();
    await dispatch(startUploading(selectedFile,nota));
    // dispatch(startNewNote(nota))
    // setTimeout(() => {
      // setNota(initialState);
      // dispatch(activeNote(null))
      // console.log('listo');
      
      // return <Navigate to={`/nota/${active.idNota}`} />
      // console.log(active);
    // }, 3000);

    
    // dispatch(setNotes([]));
    // dispatch(setIndex((index*(-1))-1));
    // dispatch(setHasMore(true));
      dispatch(resetIndexNotes());
      // await dispatch(startLoadingNotes());
    setButtonDisabled(false);
    // document.getElementById('goHome').click();


  //   dispatch(activeProfile(null));
  // dispatch(setNotesProfile([]));
  // dispatch(setIndexProfile((index*(-1))-1));
  // dispatch(setHasMoreProfile(true));
    navigate('/notas',{replace:true});
  }
  const onChangeEditor = (content) => {
    setNota(() => ({ ...nota, contenido: content}))
  }
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     // dispatch(startUploading(file));
  //     console.log(file);
  //      dispatch(activeNote({...nota}))
  //   }
  // }
  useEffect(() => {
    dispatch(activeNote({...nota}))
    // console.log(selectedFile);
    
  }, [nota,dispatch]);
  // if(active){
  //   return <Navigate to="/" />
  // }
  return (
    <div>
      <TopNavigation />
      {/* <Navbar /> */}
      {/* <MaterialUIDrawer /> */}
      <h1 className='font-face-cg' style={{textAlign:'center'}}>Nueva Nota</h1>
      <div className="container">
      
      <form  style={{display:'flex',flexDirection:'column',gap:'30px'}} onSubmit={handlePublish}>
        {/* <input type="text" name="titulo" value={titulo} autoComplete="off" onChange={handleInputChange} className="form-control" placeholder="Titulo de la Nota"></input> */}
        <div style={{margin:'20px 0'}}>
            {
              !selectedFile && <div style={{fontFamily:'Arial'}}>Seleccione una imagen para la portada</div>
            }
            <input id="getFile" style={{fontFamily:'Arial'}} type='file' accept="image/*" onChange={onSelectFile} disabled={buttonDisabled} required={true} />
            {selectedFile &&  <img src={preview} alt="" width="80%" 
              style={{
                height:'250px',
                display:'block',
                textAlign:'center',
                marginTop:'20px',
                marginLeft:'auto',
                marginRight:'auto',
                borderRadius:'10px',
                objectFit:'cover'
                }}/> }
        </div>
        <TextField
          id="outlined-textarea"
          label="Título de la nota"
          placeholder="Titulo de la nota"
          multiline
          name="titulo"
          value={titulo}
          disabled={buttonDisabled}
          required={true}
          onChange={handleInputChange}
        />
        {/* <input type="text" name='tag' value={tag} autoComplete="off" onChange={handleInputChange} className="form-control" placeholder="Ingresa algunos tags o etiquetas"></input> */}
        <div className={`tags-input-container ${buttonDisabled ? "disabled-div" : ""}`} tabIndex="1">
            { tags.map((etiqueta, index) => (
                <div className="tag-item" key={index}>
                    <span className="text" style={{fontFamily:'Arial'}}>{etiqueta}</span>
                    <span className="close" style={{paddingBottom:'0px'}} onClick={() => removeEtiqueta(index)}>&times;</span>
                </div>
            )) }
            <input onKeyDown={handleKeyDown} type="text" className="tags-input" placeholder="Escribe etiquetas y presiona Enter para crearlas" />
        </div>
        <TextField
          id="outlined-textarea"
          label="Link del recurso"
          placeholder="Pega aquí el link del recurso (opcional)"
          multiline
          name="link"
          value={link}
          disabled={buttonDisabled}
          required={false}
          onChange={handleInputChange}
        />
        <Editor
         apiKey='o6vn7duhwonkb0ofp3ps4k9p6c7t8utue9nzc7fpwmydpcit'
         onInit={(evt, editor) => editorRef.current = editor}
         initialValue=""
         value={contenido}
         plugins='lists code image'
         disabled={buttonDisabled}
         init={{
           height: 500,
           
          selector:'textarea#file-picker',
          plugins:'image code preview fullscreen',
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | image | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | help | fullscreen preview',
          image_title: true,
          automatic_uploads: true,
          file_picker_types: 'image',
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = async function () {
              var file = this.files[0];
              const cloudUrl = 'https://api.cloudinary.com/v1_1/dewxeiala/image/upload';
            let formData = new FormData();
            formData.append('upload_preset','journal-react');
            formData.append("file", file);
      
            try {
              const resp = await fetch(cloudUrl,{method:'POST',body:formData});
              if(resp.ok){
                const cloudResp = await resp.json();
                // success(cloudResp.secure_url);
                cb(cloudResp.secure_url, { title: file.name });
              }else{
                throw await resp.json()
              }
            } catch (error) {
              // console.log("Error Image del Contenido: ",error);
              return;
            }

            };
            input.click();
          }
          ,

           image_list:[
            {title: 'My image 1', value: 'https://res.cloudinary.com/dewxeiala/image/upload/v1657289423/etyo6mvxkn7pqki6fcjb.png'},
            {title: 'My image 2', value: 'https://res.cloudinary.com/dewxeiala/image/upload/v1657243221/f3gxxafimcd07469gogk.png'}
           ]
           ,
           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      
         }}
         onEditorChange={onChangeEditor}
         />
        <button id='publicar' disabled={buttonDisabled} type="submit" className="btn btn-primary btn-lg font-face-cg" style={{width:'200px',fontSize:'1rem',borderRadius:'20px'}}>Publicar</button>
      </form>
         {/* <button onClick={log}>Log editor content</button> */}
         <br />
         <Link to="/" id='goHome' style={{display:'none'}}>HOME</Link>

      </div>
      </div>
  )
}

export default NewNote