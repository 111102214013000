import React, { useState } from "react";
import {useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";

const CommentForm = ({
  handleSubmit,
  submitLabel,
  hasCancelButton = false,
  initialText="",
  handleCancel,
}) => {
  const {uid} = useSelector(state=>state.auth);
  const [text, setText] = useState(initialText);
  const isTextareaDisabled = text.length === 0;
  const [disabledFormBtn, setDisabledFormBtn] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (event) => {
    event.preventDefault();
    setDisabledFormBtn(true);
    if (uid) {
    handleSubmit(text);
    setText("");
    setDisabledFormBtn(false);
    }else{
      // console.log('se debe logear primero');
      setDisabledFormBtn(false);
      navigate(`/auth/login`);
    }
  };
  return (
    <form onSubmit={onSubmit}>
      <textarea
        name=""
        id=""
        cols="30"
        rows="10"
        className="comment-form-textarea"
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <button className="comment-form-button" disabled={isTextareaDisabled}>
        {submitLabel}
      </button>
      {hasCancelButton && (
        <button type="button" className="comment-form-button comment-form-cancel-button" onClick={handleCancel}>Cancelar</button>
      )}
    </form>
  );
};

export default CommentForm;
