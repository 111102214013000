import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import './PreviewCard.css'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';



import IconButton from '@mui/material/IconButton';

import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
const PreviewCard=({ linkData }) =>{
  const theme = useTheme();
  // const handleClickCard = (e) => {
  //   e.preventDefault();
  //   document.getElementById('alink').click();
  // }
  return (
  //   <div style={{maxWidth:'345px'}}>
  //   <a className="preview" href={linkData.url} target="blank" >
  //    <Card sx={{ maxWidth: 345 }} >
     
  //   <CardActionArea>
  //     <img src={linkData.image} alt="" height="200px"/>
  //      <CardContent>
  //         <Typography gutterBottom variant="h5" component="div">
  //           {linkData.title}
  //         </Typography>
  //        <Typography variant="body2" color="text.secondary" className='text-cuted'>
          
  //       {linkData.description}  
          
  //         </Typography>
  //        <Typography variant="h6" component="div" sx={{marginTop:'10px'}}>
  //           {linkData.source}
  //        </Typography>
  //       </CardContent>
  //     </CardActionArea>
  //  </Card>
  //   </a>
  //   </div>


<div style={{maxWidth:'645px'}}>
<a className="preview-a" href={linkData.url} target="blank" >
 <Card sx={{ maxWidth: 645 }} >
 
<div className='card-link-preview'>
  <img src={linkData.image} alt="" height="200px" className='imagen-link-preview' style={{objectFit:'cover',backgroundPosition:'center',backgroundSize:'cover'}}/>
   <CardContent>
      <Typography gutterBottom variant="h5" component="div">
      {linkData.title}
      </Typography>
     <Typography variant="body2" color="text.secondary" className='text-cuted'>
     {linkData.description}  
      </Typography>
     <Typography variant="h6" component="div" sx={{marginTop:'10px'}}>
     {linkData.source}
     </Typography>
    </CardContent>
  </div>
</Card>
</a>
</div>

  )
}
export default PreviewCard;