import React from 'react'
import './Footer.css'
import logoLab from './../assets/lab-fondo-oscuro.png'
import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import {  Link as MuiLink } from '@mui/material'
const Footer = () => {
  return (
    <div className="main-footer">
    
      <div className="row-footer">
        {/* <div className='row-footer-content'> */}
        <div className="col">
          <Typography sx={{fontFamily:'Arial'}}> <Box sx={{ fontWeight: 'bold', m: 1, fontFamily:'Arial' }}>Conoce más</Box></Typography>
          <div className="list-unstyled" style={{display:'flex',flexDirection:'column',gap:'10px'}}>
          <Link to="/proyecto" style={{color:'#fff'}}>Sobre el proyecto</Link>
            <Link to="/metodologia" style={{color:'#fff'}}>Metodología</Link>
            <Link to="/documentos" style={{color:'#fff'}}>Documentos relevantes</Link>
          
          </div>
        </div>
        {/* </div> */}
        <div className="col" style={{textAlign:'center'}}>
          <Typography sx={{fontFamily:'Arial'}}>Una iniciativa de:</Typography>
          {/* <SvgLogoOscuro width="250px" /> */}
      
          <MuiLink href="https://labtecnosocial.org/" target="_blank">
          <img src={logoLab} alt="" width="100px" />
          </MuiLink>
        </div>
      </div>

    </div>
  
  )
}

export default Footer