import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Footer from "./Footer";
import TopNavigation from "./TopNavigation";
import Button from "@mui/material/Button";

import CodeIcon from "@mui/icons-material/Code";
import { color } from "@mui/system";

const Herramientas = () => {
  return (
    <div>
      <TopNavigation />
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ marginTop: "46px", fontWeight: "bold" , color:"#081d4b"}}>
          Herramientas de Extraccion de Datos
        </Typography>
        <Typography
          variant="h6"
          sx={{ marginTop: "12px", fontWeight: "bold", marginBottom: "8px" , color:"#081d4b"}}
        >
          Herramienta para extraer datos de convocatorias
        </Typography>
        <Typography variant="body1" align="justify">
          La herramienta "Recupera SICOES" es un conjunto de funciones de
          javascript diseñado para facilitar el acceso a los datos de
          contrataciones públicas en Bolivia. La herramienta utiliza
          bookmarklets que se pueden instalar en cualquier navegador moderno y
          que permiten recuperar automáticamente las tablas HTML generadas por
          el buscador de SICOES. Esto permite a los ciudadanos, periodistas,
          analistas e investigadores acceder fácilmente a los datos de
          contrataciones públicas, en lugar de tener que realizar procedimientos
          manuales, lentos y propensos a errores. Los datos se recuperan en
          formato CSV para su fácil uso, reutilización y redistribución libre.
          La herramienta busca ser amigable para cualquier persona interesada en
          estas temáticas, y los scripts funcionan simplemente al dar clic en
          botones.
          <br />
        </Typography>
        <Link
          sx={{
            textDecoration: "none",
            marginTop: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "3px",
          }}
          href="https://labtecnosocial.org/recupera-datos-de-sicoes/"
        >
          <Button variant="outlined" size="large" startIcon={<CodeIcon />}>
            Ir a la Herramienta
          </Button>
        </Link>
        <Typography
          variant="h6"
          sx={{ marginTop: "25px", fontWeight: "bold", marginBottom: "8px", color:"#081d4b"}}
        >
          Herramienta para extraer datos de fichas de PDF
        </Typography>

        <Typography variant="body1" align="justify">
          Esta herramienta escrita en Python permite extraer tablas de fichas en
          PDF de convocatorias. Específicamente, la herramienta extrae las
          tablas de items, adjudicados y montos totales de las fichas en PDF. El
          proceso de extracción se realiza mediante el uso de varias librerías
          de Python, como Pandas, NumPy, Pathlib, Tabula y Unidecode, y se lleva
          a cabo en varias etapas. En primer lugar, se listan los archivos PDF
          presentes en una carpeta específica. A continuación, se extraen las
          tablas de los PDF y se almacenan en una lista. Luego, se procesan las
          tablas de items y adjudicados para obtener las columnas relevantes y
          se concatenan en una sola tabla. Finalmente, se guardan las tablas
          limpias y ordenadas en un archivo CSV. La herramienta también incluye
          funciones para eliminar encabezados de las tablas y para obtener
          índices relevantes en las tablas extraídas.
        </Typography>
        <Link
          sx={{
            textDecoration: "none",
            marginTop: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          href="https://github.com/lab-tecnosocial/datos-sicoes/blob/main/scripts/python/extract-tables2.ipynb"
        >
          <Button variant="outlined" size="large" startIcon={<CodeIcon />}>
            Ir a la Herramienta
          </Button>
        </Link>
      </Container>
      <div style={{ marginTop: "50px" }}></div>
      <Footer />
    </div>
  );
};

export default Herramientas;
