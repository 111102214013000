import React, { useEffect, useState } from "react";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Navbar from "./Navbar";
import { useDispatch, useSelector } from "react-redux";
import './Profile.css'
import { activeProfile, getUser, profileLogout, setHasMoreProfile, setHasMoreStarProfile, setIndexProfile, setIndexStarProfile, setNotesProfile, setNotesStarProfile, startLoadingNotesProfile, startLoadingNotesStarProfile, startUpdateDataProfile, startUploadingImageProfile } from "../redux/actions/profile";
import {  useNavigate, useParams } from "react-router-dom";
import NotesProfile from "./NotesProfile";
import { Button } from "@mui/material";
import { startLogout } from "../redux/actions/auth";


import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NotesStarProfile from "./NotesStarProfile";
import MaterialUIDrawer from "./MaterialUIDrawer";
import TopNavigation from "./TopNavigation";

const Profile = () => {
  const navigate = useNavigate();
  const {idUsuario} = useParams();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const {name,uid} = useSelector(state=>state.auth);
  const {active,notes,index,hasMore,indexStar} = useSelector(state=>state.profile);
  // const [usuario, setUsuario] = useState({nombre:active?.nombre,fotoUsuario:active?.fotoUsuario,descripcion:active?.descripcion});
  
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // dispatch(activeProfile(null));
  //   dispatch(setNotesProfile([]));
  //   dispatch(setHasMoreProfile(true));
  //   dispatch(setIndexProfile(index*(-1)));
  //   dispatch(startLoadingNotesProfile(idUsuario));

  //   dispatch(setNotesStarProfile([]));
  //   dispatch(setHasMoreStarProfile(true));
  //   dispatch(setIndexStarProfile(indexStar*(-1)));
  //   dispatch(startLoadingNotesStarProfile(idUsuario));
    dispatch(getUser(idUsuario));
  }, [dispatch,idUsuario]);

  const handleLogout=() => {
    dispatch(startLogout());
    dispatch(profileLogout());
    navigate('/',{replace:true});
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(startUploadingImageProfile(file,idUsuario));
      // dispatch(activeProfile({...active,fotoUsuario:`https://res.cloudinary.com/dewxeiala/image/upload/v1658362469/qrfxngcqjbvfcdo17nrd.png`}))
      // console.log(file);
      // setState(file)
    }
  }
  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const nombre = document.getElementById('nombre').value;
    const descripcion = document.getElementById('descripcion').value;

    // dispatch(activeProfile({...active,nombre:nombre,descripcion:descripcion}));
    dispatch(startUpdateDataProfile(nombre,descripcion,idUsuario));
    
    // console.log(nombre,descripcion);
    
    handleClose();
  }
  return (
    <div>
      <TopNavigation />
       {/* <Navbar /> */}
       {/* <MaterialUIDrawer /> */}
       <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{fontFamily:'CenturyGothic',fontSize:'1rem'}}>Mi perfil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="nombre"
            label="Nombre de usuario"
            type="text"
            name="nombre"
            defaultValue={active?.nombre}
            fullWidth
           
            autoComplete="off"
            variant="standard"
          />
          <TextField
            autoFocus
            name="descripcion"
            margin="dense"
            id="descripcion"
            label="Descripción"
            defaultValue={active?.descripcion}
            type="text"
            fullWidth
            autoComplete="off"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{fontSize:'1rem',fontFamily:'CenturyGothic'}}>Cancelar</Button>
          <Button onClick={handleUpdateProfile} sx={{fontSize:'1rem',fontFamily:'CenturyGothic'}}>Guardar cambios</Button>
        </DialogActions>
      </Dialog>
      {/* <h1 style={{marginTop:'80px'}}>Profile</h1> */}
      <div className="profile-page">
      <div className="user-info">
      
        <div className="container-profile">
          {
            idUsuario === uid && 
            <Button variant="outlined" className="actions-nota-btn" sx={{textTransform:"capitalize",position:'absolute',right:'10px',fontSize:'0.9rem',fontFamily:'Arial',backgroundColor:'var(--primary-color)',color:'var(--white-color)'}} onClick={handleLogout}>
            <i className="fa-solid fa-arrow-right-from-bracket" sx={{color:'#fff'}}></i>&nbsp; Cerrar sesión
          </Button>
          }
        
          <div className="row">
            <div className="col-xs-12 col-md-10 offset-md-1">
              <div className="container-campos">
                 <div className="profile-pic">
             {
              idUsuario === uid &&
              <>
               <label className="-label" htmlFor="file">
                <span className="glyphicon glyphicon-camera"></span>
                <span>Cambiar imagen</span>
              </label>
              <input id="file" type="file" onChange={handleFileChange}/>
              </>
             }
              <img src={active?.fotoUsuario === 'default' ? `https://res.cloudinary.com/dewxeiala/image/upload/v1658443472/male_user_lb5fld.svg` : active?.fotoUsuario} id="output" width="200" alt=""/>
            </div>
              {/* <img src={active?.fotoUsuario} className="user-img" alt="" /> */}
                <h4 className="font-face-cg">{active?.nombre}</h4>
                <p className="font-face-arial">{active?.descripcion}</p>
                {
              idUsuario === uid &&
              <Button variant="outlined" className="actions-nota-btn" onClick={handleClickOpen} sx={{textTransform:"capitalize",fontSize:'0.9rem',fontFamily:'Arial',backgroundColor:'var(--primary-color)',color:'var(--white-color)'}}>
                <i className="fa-solid fa-pen-to-square" sx={{color:'#fff'}}></i>&nbsp; Editar mis datos
                </Button>
             }
                
              </div>
           
            </div>
          </div>
        </div>
      </div>
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" centered={true}>
            <Tab label="Sus notas" value="1" sx={{fontFamily:'CenturyGothic',fontSize:'0.9rem'}}/>
            
            <Tab label="Notas favoritas" value="2" sx={{fontFamily:'CenturyGothic',fontSize:'0.9rem'}}/>
            {/* <Tab label="" value="3" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <NotesProfile idUser={idUsuario}/>
        </TabPanel>
        <TabPanel value="2">
          <NotesStarProfile idUser={idUsuario} />
        </TabPanel>
        {/* <TabPanel value="3">Item Three</TabPanel> */}
      </TabContext>
    </Box>
      </div>
   
    </div>
  );
};

export default Profile;
