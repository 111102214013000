import { types } from "../../types/types"
import { firebase, googleAuthProvider } from "../../firebase/firebase-config";
import { finishLoading, startLoading } from "./ui";
import { supabase } from "../../supabase/client";


export const startLoginEmailPassword = (email,password) => {
  
  return async (dispatch)=>{
      dispatch(startLoading());
      // firebase.auth().signInWithEmailAndPassword(email,password).then(
      //   ({user})=>{
      //     dispatch(login(user.email,user.displayName));
      //     dispatch(finishLoading());
      //   }
      // ).catch(e=>{
      //   console.log(e)
      //   dispatch(finishLoading());
      //   console.log('error')
      // })
      const { user, session, error } = await supabase.auth.signIn({
        email: email,
        password: password,
      })
      if(error===null){
        //Traer los datos del Usuario
          const {data,error} = await supabase.from('usuarios').select('nombre,fotoUsuario').eq('idUsuario',user.id);
          // console.log('data usuario',data)
          dispatch(login(user.id,data[0].nombre,data[0].fotoUsuario));
          // dispatch(login(user.id,user.user_metadata.full_name));
          dispatch(finishLoading());
      }else{
        dispatch(finishLoading());
        // console.log(error)
      }
     
    
  }
}
export const startRegisterWithEmailPasswordName = (email,password,name)=>{
  return async(dispatch)=>{
    // firebase.auth().createUserWithEmailAndPassword(email,password).then(
    //   async({user})=>{
    //       await user.updateProfile({displayName:name});
    //       // console.log(user);
    //       dispatch(login(user.uid,user.displayName));
    //   }
    // ).catch(e=>{
    //   console.log(e)
    //   console.log('error')
    // })
    const { user, session, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    },
    {
      data: { 
        avatar_url: 'default',
        name: name, 
      }
    });
    // console.log(user,' - ',session,' - ',error);
    // if (error===null) {
    //   //Guardando sus datos en la BD
    //   console.log('Guardando usuario en DB')
    //   const { data, error } = await supabase
    //       .from('usuarios')
    //       .insert([
    //         {
    //           idUsuario: user.id.toString(),
    //           tipoUsuario: 'autor',
    //           nombre: name,
    //           descripcion: "",
    //           correo: user.email.toString(),
    //           fotoUsuario: "default"
    //         }
    //       ]).single();
    //       if(error===null){
    //         console.log('Iniciando Sesion')
    //         //ya esta autenticado en supabase, solo fata hacer login con redux state
    //         dispatch(login(user.id.toString(),name));
    //       }else{
    //         console.log('Hubo un error ',error)
    //       }
      
    // }
   
  }
}
export const startGoogleLogin = ()=>{
  return async(dispatch)=>{
  // firebase.auth().signInWithPopup(googleAuthProvider)
  // .then(({user})=>{
  //  dispatch(login(user.uid,user.displayName));
  // })
  try {
     const { user, session, error } = await supabase.auth.signIn({
    // provider can be 'github', 'google', 'gitlab', and more
    provider: 'google'
  });
    // console.log(user,'=>',session,'=>',error);
  if(error) throw new Error('Un error ocurrio durante la autenticacion')
  // console.log('Guardando usuario Google en BD');
  const u = await supabase.auth.user();
  // console.log('HOLAAAAAAAAAAA',JSON.stringify(u));
  const { data, error:err } = await supabase
        .from('usuarios')
        .insert([
          {
            idUsuario: user.id.toString(),
            tipoUsuario: 'autor',
            nombre: user.user_metadata.full_name,
            descripcion: "",
            correo: user.email.toString(),
            fotoUsuario: user.user_metadata.avatar_url
          }
        ]);
        if(err===null){
          // console.log('Se guardo el usuario Google')
          //ya esta autenticado en supabase, solo fata hacer login con redux state
          dispatch(login(user.id.toString(),user.user_metadata.full_name,user.user_metadata.avatar_url));
        }else{
          // console.log('Hubo un error al guardar al usuario Google',err)
        }
  } catch (error) {
    // console.log(error)
  }
 
  // console.log(user)
  // if(error===null){
  //   // dispatch(login(user.id,'Google'))
   
  // }else{
  //   console.log('Error al iniciar sesion con Google',error);
  // }
  }
}
export const login = (uid,displayName,fotoUsuario) => {
  return {
    type: types.login,
    payload: {uid,displayName,fotoUsuario}
  }
}

export const startLogout = () => {
  return async(dispatch)=>{
    // await firebase.auth().signOut();
    // dispatch(logout());
    const { error } = await supabase.auth.signOut();
    if(error===null){
      dispatch(logout());
    }else{
      // console.log(error)
    }
  }
}
export const logout = () => {
 return {
   type:types.logout
 } 
}