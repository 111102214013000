import React, { useEffect, useMemo, useState } from 'react'
import { Link,useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useSelector,useDispatch } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { supabase } from '../supabase/client';
import './NotaScreen.css';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
// import MicrolinkCard from "react-microlink";
// import LinkPreview from '@ashwamegh/react-link-preview'
import {ShareSocial} from 'react-share-social' 
// import Microlink from '@microlink/react';
// import { ReactTinyLink } from 'react-tiny-link'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StarIcon from '@mui/icons-material/Star';
import { activeNote, setHasMore, setIndex, setNotes, startLoadingNotes } from '../redux/actions/notes';
import { setHasMoreProfile, setIndexProfile, setNotesProfile } from '../redux/actions/profile';
import NoteComments from './NoteComments';
import { login } from '../redux/actions/auth';
import { Box, CircularProgress, Typography } from '@mui/material';
import LinkPreview from './link/LinkPreview';
// import { getLinkPreview, getPreviewFromContent } from "link-preview-js";
import {Helmet} from "react-helmet";
const style = {
  background: 'linear-gradient(45deg, var(--alternative-color) 30%,var(--alternative-color)  90%)',
  borderRadius: 3,
  border: 0,
  color: 'white',
  padding: '0 30px',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
};
const options = {year: 'numeric', month: 'long', day: 'numeric' };
const NotaScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {idNota} = useParams();
  const {auth} = useSelector(state=>state);
  const {uid} = useSelector(state=>state.auth);
  const {notes,active,index} = useSelector(state=>state.notes);
  const {active:activeProfile,index:indexProfile} = useSelector(state=>state.profile);
  const [checking, setChecking] = useState(true);
  const [likes, setLikes] = useState(active?.nFavs);
  const [open, setOpen] = useState(false);
  const [li, setLi] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
const [disabledLikeBtn, setDisabledLikeBtn] = useState(false);
const [periodista, setPeriodista] = useState(false);
  const getNotaById = (id) =>{
    return notes.find(nota=> nota.idNota===id);
  }
  const nota = useMemo(() => getNotaById(idNota), [idNota]);

  useEffect(() => {
    const getNote = async(idNote) => {
      const {data,error} = await supabase.from('notas').select(`
      *,
      usuario:idUsuario (nombre,fotoUsuario)
      `).eq('idNota',idNota);
      if (data.length===0) {
        // console.log('No se encontro esta nota, ya no existe');
        setChecking(false);
      }else{
        dispatch(activeNote(data[0]));
        // let it = await grabity.grabIt("https://github.com/e-oj/grabity");
        // setLi(it)
        setChecking(false);
        setLikes(data[0].nFavs);
      }
      
    }

    // console.log(nota);
    if(nota===undefined){
      // console.log('es Undefined');
      //hacer peticion a supabase
      getNote(idNota);
    }else{
      // console.log('NO es undefined')
      dispatch(activeNote(nota));
      setLikes(nota.nFavs);
      setChecking(false);
    }
  }, [nota,idNota,dispatch,setChecking]);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReturn = ()=>{

    // if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    // } else {
    //   navigate('/notas', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    // }
  }
const handleUserProfile = () => {
    // dispatch(setNotesProfile([]));
    // dispatch(setIndexProfile(indexProfile*(-1)));
    // dispatch(setHasMoreProfile(true))
      
  navigate(`/profile/${active?.idUsuario}`)
}
const handleEdit = () => {
  navigate(`/editNote/${idNota}`)
}
const handleDelete = async(e) => {
  setButtonDisabled(true);
  e.preventDefault();
  //borrando comentarios
  const { data:dattt, error:errrr } = await supabase
  .from('comentarios')
  .delete()
  .match({ idNota:idNota });
// console.log('Se borro comentarios?', errrr);

  const { data:da, error:er } = await supabase
  .from('favs')
  .delete()
  .match({idNota:idNota });
  // console.log('Se borro favoritos?',er);
  const { data, error } = await supabase
  .from('notas')
  .delete()
  .match({ idNota: idNota });
  // console.log('Se borro la nota?', error);
  // console.log('Res eliminar data',data);
  // console.log('Res eliminar error',error);
  await  dispatch(activeNote(null))
 
  await dispatch(setNotes([]));
  await dispatch(setIndex(index*(-1)));
  await dispatch(setHasMore(true));
    setButtonDisabled(false);
     handleClose();
  // await dispatch(startLoadingNotes());
    // document.getElementById('goHome').click();
    navigate('/notas',{replace:true});
}
const handleLike = async() => {
  setDisabledLikeBtn(true);
  if (uid) {
    const {data:d,error:e} = await supabase.from('favs').select().eq('idUsuario',uid).eq('idNota',idNota);
  // console.log(d);
  // console.log(e);
  if (d.length>0) {
    //No se puede dar like, porque ya existe la row
    // console.log('Solo puedes dar like 1 vez');
    const { data:da, error:er } = await supabase
  .from('favs')
  .delete()
  .match({ idUsuario:uid,idNota:idNota });
    // console.log(da);
    // console.log(er);
  await supabase.rpc('vote', { 
    quote_id: idNota, increment_num: -1 
  });
  const {data:dat,error:err} = await supabase.from('notas').select().eq('idNota',idNota);
  setLikes(dat[0].nFavs)
  // console.log(dat);
  // console.debug(err);
  setDisabledLikeBtn(false);
    return;
  }
  const { data,error } = await supabase.from('favs')
    .insert([
       {
        idUsuario:uid,
        idNota:idNota 
       }
    ])
    .single();
    // console.log(data);
    // console.log(error);
   await supabase.rpc('vote', { 
    quote_id: idNota, increment_num: 1 
  });
  const {data:dat,error:err} = await supabase.from('notas').select().eq('idNota',idNota);
  // console.log(dat);
  // console.log(err);
  setLikes(dat[0].nFavs)
  setDisabledLikeBtn(false);
  }else{
    // console.log('se debe logear primero');
    setDisabledLikeBtn(false)
    navigate(`/auth/login`);
  }
  
}
useEffect(() => {
  const traer = async (id) => {
    const {data,error} = await supabase.from('usuarios').select('nombre,fotoUsuario').eq('idUsuario',id);
    const {data:data2,error:error2} = await supabase.from('periodistas').select('*').eq('idUsuario',id);
        // console.log('data usuario',data)
        dispatch(login(id,data[0].nombre,data[0].fotoUsuario))
        if (data2.length>0 && error2===null) {
          //tiene el rol periodista
          // console.log('Si es periodista');
          setPeriodista(true);
        }else if (data2.length===0) {
          //no existe periodista, este usuario no es periodista
          // console.log('No es periodista');
          setPeriodista(false);
        } 
  }
  const user = supabase.auth.user();
  if(user!==null){
    if(auth.name){
      traer(user.id);
    }else{
      traer(user.id);
    }
  }else{
    // console.log('de Navbar no hay auth')
  }

}, [dispatch]);
useEffect(() => {
  // getLinkPreview("https://labtecnosocial.org/opiniones-creencias-vacunas-rrss-bolivia/").then((data) =>
  // console.debug(data)
// );
}, []);
if (checking) {
  return <Box sx={{ display: 'flex',justifyContent:'center' }}>
  <CircularProgress sx={{color:'var(--primary-color)'}} />
</Box>;
}
if (nota===undefined) {
  return (
    <div>
        <Helmet>
                <meta charSet="utf-8" />
                <title>{active?.titulo}</title>
                <link rel="canonical" href={`https://contratosabiertos-bo.org/notas/${idNota}`} />
            </Helmet>
    <div className="actions">
    <Button variant="outlined" className='actions-nota-btn' size='small' sx={{textTransform:"capitalize",backgroundColor:'var(--primary-color)'}} onClick={handleReturn}>
<ArrowBackIcon className='button-action' sx={{color:'#fff'}}/>
</Button>
{
  uid=== active.idUsuario
  // && periodista 
  &&
<div className='actions-nota'>
<Button variant="outlined" className='actions-nota-btn' size='small' sx={{textTransform:"capitalize",backgroundColor:'var(--primary-color)',color:'var(--white-color)'}} onClick={handleEdit}>
  <EditIcon className='button-action' sx={{color:'#fff'}}/>&nbsp; Editar nota
</Button>
<Button variant="outlined" className='actions-nota-btn' size='small' sx={{textTransform:"capitalize",backgroundColor:'var(--primary-color)',color:'var(--white-color)'}} onClick={handleClickOpen}>
<DeleteIcon className='button-action' sx={{color:'#fff'}}/>&nbsp; Eliminar nota
</Button>
<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{fontFamily:'CenturyGothic'}} fontSize="18px">
          {"¿Está seguro de eliminar esta nota?"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description" fontSize="14px" >
            Al eliminar esta nota se eliminará completamente y los enlaces a esta nota ya no funcionarán
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{fontSize:'12px',fontFamily:'CenturyGothic'}} disabled={buttonDisabled}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus sx={{fontSize:'12px',fontFamily:'CenturyGothic'}} disabled={buttonDisabled}>
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
</div>
}


    </div>
    {/* <div  alt="" height="500px" width="100%" style={{backgroundImage:'url(https://res.cloudinary.com/dewxeiala/image/upload/v1654784917/uk5wlzvf7pawi6zc1xjn.jpg)',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}} >

    </div> */}

<div className='blog-post-hero' style={{backgroundImage:"url(" + active?.imgPrincipal + ")"}}>

      </div>
    <div className="container">
      
      <div className="nota-container">
      <Typography variant="h3" component="h2" style={{fontFamily:"CenturyGothic"}}>
      {active?.titulo}
</Typography>;
        {/* <h1 className='font-face-cg'>{active?.titulo}</h1> */}
    <div className='font-face-arial' style={{display:'flex',alignItems:'center',marginTop:'15px',marginBottom:'20px'}}>
      {/* <Avatar alt="" src={article?.usuario?.fotoUsuario}  sx={{ width: 28, height: 28,marginRight:'5px'}} />  */}
      {
        (active?.usuario?.fotoUsuario === 'default') ?
      //  <IconButton disabled  sx={{marginRight:'5px'}}>
      <div onClick={handleUserProfile} className="usuario">
        <PersonRoundedIcon sx={{marginRight:'5px'}} />
      </div>
        // </IconButton> 
        :
        // <IconButton disabled  sx={{marginRight:'5px'}}>
          <img src={active?.usuario?.fotoUsuario} className="usuario"  alt="" onClick={handleUserProfile} style={{width:'22px',height:'22px',borderRadius:'50%',marginRight:'5px'}} />
          // <Avatar alt="" src={article?.usuario?.fotoUsuario}  sx={{ width: 22, height: 22,marginRight:'5px'}} />
        // </IconButton>
      }
      por&nbsp;<Link to={`/profile/${active?.idUsuario}`}>{active?.usuario?.nombre}</Link>&nbsp;el {new Date(active?.fechaHora).toLocaleDateString('es-ES', options)} 
    </div>
    <div dangerouslySetInnerHTML={{ __html: active?.contenido }} className="notaContent font-face-arial"></div>
    {
      active?.link !== '' && active!==null && 
        <div style={{marginBottom:'30px'}}>
          <LinkPreview linkUrl={active?.link} />
          {/* <MicrolinkCard  
          url="https://www.commonapp.org/"
          size="normal"
          target="_blank"
          
        /> */}
        {/* <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          url={active?.link} 
        /> */}
        {/* <LinkPreview url="https://reactjs.org"/> */}
        {/* <Microlink url='https://labtecnosocial.org/recupera-datos-de-sicoes/' /> */}
        </div>
        
      }
    <div>
    <div>
      <IconButton color="secondary" onClick={handleLike} disabled={disabledLikeBtn}>
    {/* <ThumbUpIcon fontSize='large' /> */}
    <StarIcon fontSize='large' sx={{color:'var(--accent-color)'}}/> &nbsp;{likes}
        {/* <AlarmIcon /> */}
      </IconButton>
      </div>
    </div>
    <div>
      <h4 className='font-face-cg'>Comparte la nota</h4>
      <ShareSocial 
     style={style}
     url ={`https://contratosabiertos-bo.org/notas/${idNota}`}
     socialTypes={['facebook','twitter','reddit','linkedin']}
   />
    </div>
    </div>
    <div className="nota-comments">
        <NoteComments />
      </div>
      </div>
    

  </div>
  )
}
  return (
    <div>
        <Helmet>
                <meta charSet="utf-8" />
                <title>{active?.titulo}</title>
                <link rel="canonical" href={`https://contratosabiertos-bo.org/notas/${idNota}`} />
            </Helmet>
      <div className="actions">
      <Button variant="outlined" className='actions-nota-btn' size='small' sx={{textTransform:"capitalize",backgroundColor:'var(--primary-color)'}} onClick={handleReturn}>
<ArrowBackIcon className='button-action' sx={{color:'#fff'}}/>
</Button>
{
  uid === active.idUsuario
  // && periodista 
  &&
  <div className='actions-nota'>
<Button variant="outlined" className='actions-nota-btn' size='small' sx={{textTransform:"capitalize",backgroundColor:'var(--primary-color)',color:'var(--white-color)'}} onClick={handleEdit}>
  <EditIcon className='button-action' sx={{color:'#fff'}}/>&nbsp; Editar nota
</Button>
<Button variant="outlined" className='actions-nota-btn' size='small' sx={{textTransform:"capitalize",backgroundColor:'var(--primary-color)',color:'var(--white-color)'}} onClick={handleClickOpen}>
<DeleteIcon className='button-action' sx={{color:'#fff'}}/>&nbsp; Eliminar nota
</Button>
<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='font-face-cg' sx={{fontFamily:'CenturyGothic'}} fontSize="18px">
          {"¿Está seguro de eliminar esta nota?"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description" fontSize="14px" sx={{fontFamily:'Arial'}} >
            Al eliminar esta nota se eliminará completamente y los enlaces a esta nota ya no funcionarán
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{fontSize:'12x',fontFamily:'CenturyGothic'}} disabled={buttonDisabled}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus sx={{fontSize:'12px',fontFamily:'CenturyGothic'}} disabled={buttonDisabled}>
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog>
</div>
}

      </div>
      {/* <div  alt="" height="500px" width="100%" style={{backgroundImage:'url(https://res.cloudinary.com/dewxeiala/image/upload/v1654784917/uk5wlzvf7pawi6zc1xjn.jpg)',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'center'}} >

      </div> */}

<div className='blog-post-hero' style={{backgroundImage:"url(" + nota?.imgPrincipal + ")"}}>

        </div>
      <div className="container">
        
        <div className="nota-container">
        <Typography variant="h3" component="h2" sx={{fontFamily:'CenturyGothic'}}>
      {active?.titulo}
</Typography>;
          {/* <h1>{nota?.titulo}</h1> */}
      <div style={{display:'flex',alignItems:'center',marginTop:'15px',marginBottom:'20px'}}>
        {/* <Avatar alt="" src={article?.usuario?.fotoUsuario}  sx={{ width: 28, height: 28,marginRight:'5px'}} />  */}
        {
          (nota?.usuario?.fotoUsuario === 'default') ?
        //  <IconButton disabled  sx={{marginRight:'5px'}}>
        <div onClick={handleUserProfile} className="usuario">
          <PersonRoundedIcon sx={{marginRight:'5px'}} />
        </div>
          // </IconButton> 
          :
          // <IconButton disabled  sx={{marginRight:'5px'}}>
            <img src={nota?.usuario?.fotoUsuario} className="usuario"  alt="" onClick={handleUserProfile} style={{width:'22px',height:'22px',borderRadius:'50%',marginRight:'5px'}} />
            // <Avatar alt="" src={article?.usuario?.fotoUsuario}  sx={{ width: 22, height: 22,marginRight:'5px'}} />
          // </IconButton>
        }
        <Typography sx={{fontFamily:'Arial'}}>
          por
        </Typography>
        &nbsp;<Link to={`/profile/${active?.idUsuario}`}><Typography sx={{fontFamily:'Arial'}}>{nota?.usuario?.nombre}</Typography></Link>&nbsp;<Typography sx={{fontFamily:'Arial'}}>el {new Date(nota?.fechaHora).toLocaleDateString('es-ES', options)}</Typography> 
      </div>
      
      <div  dangerouslySetInnerHTML={{ __html: nota?.contenido }} className="notaContent"></div>
      {
      active?.link !== '' && active?.link !==null && 
        <div style={{marginBottom:'30px'}}>
          <LinkPreview linkUrl={active?.link} />
          {/* <MicrolinkCard  
          url="https://www.commonapp.org/"
          size="normal"
          target="_blank"
          
        /> */}
        {/* <Microlink url='https://youtu.be/c8mCdScm_9Y' /> */}
        {/* <ReactTinyLink
          cardSize="small"
          showGraphic={true}
          maxLine={2}
          minLine={1}
          url={active?.link} 
        /> */}
        </div>
        
      }
      <div>
      <IconButton color="secondary" onClick={handleLike} disabled={disabledLikeBtn}>
    {/* <ThumbUpIcon fontSize='large' /> */}
    <StarIcon fontSize='large' sx={{color:'var(--accent-color)'}}/> &nbsp;{likes}
        {/* <AlarmIcon /> */}
      </IconButton>
      </div>
      <div>
      <h4 className='font-face-cg'>Comparte la nota</h4>
      <ShareSocial 
     style={style}
     url ={`https://contratosabiertos-bo.org/notas/${idNota}`}
     socialTypes={['facebook','twitter','reddit','linkedin']}
   />
    </div>
      </div>
      <div className="nota-comments">
        <NoteComments />
      </div>
        </div>
      

    </div>
  )
}

export default NotaScreen