import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Footer from "./Footer";
import TopNavigation from "./TopNavigation";
import GitHubIcon from "@mui/icons-material/GitHub";
import Button from "@mui/material/Button";

const Metodologia = () => {
  return (
    <div>
      <TopNavigation />
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          sx={{ marginTop: "46px", fontWeight: "bold", color: "#081d4b" }}
        >
          Metodología
        </Typography>
        <Typography variant="body1" align="justify">
          El procedimiento que se siguió para obtener los datos de convocatorias
          relacionadas al Covid-19 del SICOES fue el siguiente:
          <ul>
            <li>
              Descargamos todas las tablas visibles de las convocatorias de
              SICOES de marzo de 2020 a enero de 2022 (87.444 convocatorias),
              con la herramienta recuperaSICOES
            </li>
            <li>
              Filtramos esas tablas buscando en el campo “objeto” las palabras
              clave relacionadas con el Covid-19: alcohol, antigeno, barbijo,
              congelador, coronavirus, covid, cuarentena, dioxido de cloro, gel,
              ivermectina, oxigeno, pandemia, PCR, puebas covid, respirador,
              sanitizador, vacuna). Estas se encuentra en la columna
              'menciones'. Esto es equivalente a hacer el filtrado con el
              buscador de SICOES mediante el campo objeto con esas mismas
              palabras clave.
            </li>
            <ul>
              <li>
                Dado que no existe información oficial del Estado sobre todas
                las contrataciones sobre Covid-19 que se hicieron, esta búsqueda
                es la mejor aproximación que encontramos.
              </li>
              <li>
                Es posible que existan más convocatorias sobre Covid-19 con otra
                búsqueda de palabras. O que falten las convocatorias sobre
                Covid-19 que no contienen palabras explicitas sobre Covid en el
                objeto de su convocatoria.
              </li>
            </ul>
            <li>
              Una vez identificados los CUCES de las convocatorias que contenían
              información sobre Covid, que se encontraron mediante la búsqueda
              de palabras clave, filtramos las convocatorias que a la fecha se
              habían adjudicado. Con esos CUCES descargamos manualmente las
              fichas en PDF, puesto que tenían un CAPCHA que no dejaba
              automatizar la descarga.
            </li>
            <li>
              Ya con las fichas en PDF, usamos una herramienta escrita en Python
              para extraer los datos de los montos totales adjudicados, los
              nombres de los proponentes adjudicados y los precios específicos
              de los items.
            </li>
            <li>
              De esta forma se han logrado construir las cuatro tablas que están
              al inicio de la plataforma:
            </li>
            <ul>
              <li>Datos de convocatorias con los montos totales adjudicados</li>
              <li>Datos de proponentes adjudicados</li>
              <li>Datos de items</li>
            </ul>
          </ul>
          Los datos que se encuentran en la pestaña "Otros temas" provienen de
          dos personas muy activas de la comunidad de datos abiertos en Bolivia:
          Mauricio Foronda y Eduardo Arraya. Puedes consultar sus páginas de
          GitHub para más detalles.
        </Typography>
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0 50px",
          }}
        >
          <Link
            sx={{
              textDecoration: "none",
              marginTop: "20px",
              border: "3px",
              marginTop: "30px",
            }}
            href="https://github.com/mauforonda/"
          >
            <Button variant="contained" size="large" startIcon={<GitHubIcon />}>
              Mauricio Foronda
            </Button>
          </Link>
          <Link
            sx={{
              textDecoration: "none",
              marginTop: "20px",
              border: "3px",
              marginTop: "30px",
            }}
            href="https://github.com/pr0nstar"
          >
            <Button variant="contained" size="large" startIcon={<GitHubIcon />}>
              Eduardo Arraya
            </Button>
          </Link>
        </Container>

        <Typography
          variant="h5"
          sx={{ marginTop: "20px", fontWeight: "bold", color: "#081d4b" }}
        >
          Recomendaciones para el uso de los datos
        </Typography>
        <Typography variant="body1" align="justify">
          Para el caso de los datos de contratos sobre COVID-19 tomar en cuenta
          las siguientes consideraciones:
          <ul>
            <li>
              Dado que la extracción de datos se hizo con procedimientos
              automatizados sobre la base de PDFs que no tienen un formato
              consistente, el 100% de la presencia de los datos no está
              garantizada. Según nuestras pruebas, sin embargo,vimos que puede
              exitir un 3% de datos perdidos o erroneos, lo que equivale a decir
              que el 97% está correcto.
            </li>
            <li>
              Entonces, para extraer alguna conclusión de los datos, se debe
              hacer una proceso de triangulación entre:
            </li>
            <ol>
              <li>Los datos en CSV proveidos</li>
              <li>Las fichas en PDF</li>
            </ol>
          </ul>
        </Typography>
      </Container>
      <div style={{ marginTop: "100px" }}></div>
      <Footer />
    </div>
  );
};

export default Metodologia;
