const convCols = [
    {
      header: 'Menciones',
      accessorKey: 'menciones',
    },
    {
      header: 'CUCE',
      accessorKey: 'cuce',
    },
    {
      header: 'Fecha de publicación',
      accessorKey: 'fecha_publicacion',
    },

    {
      header: 'Entidad',
      accessorKey: 'entidad',
    },
    {
      header: 'Tipo de contratación',
      accessorKey: 'tipo_contratacion',
    },
    {
      header: 'Modalidad',
      accessorKey: 'modalidad',
    },
    {
      header: 'Objeto',
      accessorKey: 'objeto',
    },
    {
      header: 'Departamento',
      accessorKey: 'departamento',
    },
    {
      header: 'Total adjudicado',
      accessorKey: 'total_adjudicado',
    },
    {
      header: 'Total recepcionado',
      accessorKey: 'total_recepcionado',
    },
   
  ]

const fichasCols = [
    {
      header: 'CUCE',
      accessorKey: 'cuce',
    },
    {
      header: 'Entidad',
      accessorKey: 'entidad',
    },
    {
      header: 'Número',
      accessorKey: 'numero',
    },
    {
      header: 'Descripción',
      accessorKey: 'descripcion_del_bien_o_servicio',
    },
    {
      header: 'Unidad de medida',
      accessorKey: 'unidad_de_medida',
    },
    {
      header: 'Cantidad',
      accessorKey: 'cantidad',
    },
    {
      header: 'Precio unitarido adjudicado',
      accessorKey: 'precio_unitario_adjudicado',
    },
    {
      header: 'Total adjudicado',
      accessorKey: 'total_adjudicado',
    },
    {
      header: 'Nombre del adjudicado',
      accessorKey: 'nombre_o_razon_social_del_proponente_adjudicado',
    }
  ]

const adjudicadosCols = [
    {
      header: 'CUCE',
      accessorKey: 'cuce',
    },
    {
      header: 'Número',
      accessorKey: 'numero',
    },
    {
        header: "Nombre del adjudicado",
        accessorKey: "nombre_proponente",
    },
    {
      header: 'Monto adjudicado',
      accessorKey: 'monto_adjudicado',
    },
    {
      header: 'Monto de',
      accessorKey: 'monto_de',
    },
    {
      header: 'Fecha',
      accessorKey: 'fecha_1',
    },
    {
      header: 'Monto',
      accessorKey: 'monto',
    },
    {
      header: 'RUPE',
      accessorKey: 'rupe',
    },
    {
      header: 'Estado',
      accessorKey: 'estado',
    }
  
  ]

const totalesCols = [
    {
      header: 'CUCE',
      accessorKey: 'cuce',
    },
    {
      header: 'Total Adjudicado',
      accessorKey: 'total_adjudicado',
    },
    {
      header: 'Total Recepcionado:',
      accessorKey: 'total_recepcionado',
    },
    {
        header: 'Total Referencial:',
        accessorKey: 'total_referencial',
      },
 
]

export { convCols, fichasCols, adjudicadosCols, totalesCols };
