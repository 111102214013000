import React from 'react'
import ArticlePreview from './ArticlePreview';

const ArticleListStar = ({articles}) => {
  
  if(!articles) {
		return (
			<div className="article-preview">Cargando...</div>
		);
	}

	if(articles.length === 0) {
		return (
			<div className="article-preview font-face-arial" style={{fontSize:'1.2rem'}}>
				No hay notas aquí aún...
			</div>
		);
	}

	return (
		<div>
			{
				articles.map(article => (
					<ArticlePreview key={article.nota.idNota+`Star`} article={article.nota} />
				))
			}
		</div>
	);
}

export default ArticleListStar