import React, { useEffect, useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { Link } from "react-router-dom";

import MobileMenu from "./MobileMenu";
import SvgLogoOscuro from "./SvgLogoOscuro";
import './TopNavigation.css'
import {useSelector,useDispatch} from 'react-redux';
import { supabase } from "../supabase/client";
import { login } from "../redux/actions/auth";
const useStyles = makeStyles((theme) => ({
 toolbarMa:{
  backgroundColor:'#081d4b',
  display:'flex',
  flexDirection:'row'

 },
  top:{
  width:'100%',
  display:'flex',
  flexDirection:'row',
  alignItems:'center',
  justifyContent:'space-between',
  backgroundColor:'#081d4b'
 },
 optionsNavbar:{
  display:'flex',
  flexDirection:'row',
  gap:'10px',
  
 },
 navOptionText:{
  fontSize:'1.5rem',
  fontFamily:'Arial',
  color:'#FFFFFF',
  // "&:hover":{
  //   color:'#C4C4C4',
  //   textDecoration:'none'
  // }
 },
 appBarMa:{
  width:'100%',
  backgroundColor:'#081d4b'
 },
 link:{
  padding:'10px'
  ,
  // "& :hover":{
  //   color:'#C4C4C4',
    
  // }
 }
}));

const TopNavigation = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useDispatch();
  const {auth} = useSelector(state=>state);
  const {active} = useSelector(state=>state.profile);
  const [periodista, setPeriodista] = useState(false);
  useEffect(() => {
    const traer = async (id) => {
      const {data,error} = await supabase.from('usuarios').select('nombre,fotoUsuario').eq('idUsuario',id);
      const {data:data2,error:error2} = await supabase.from('periodistas').select('*').eq('idUsuario',id);
          // console.log('data periodista',data2)
          // console.log('data periodista error',error2)
          dispatch(login(id,data[0].nombre,data[0].fotoUsuario))
          if (data2.length>0 && error2===null) {
            //tiene el rol periodista
            // console.log('Si es periodista');
            setPeriodista(true);
          }else if (data2.length===0) {
            //no existe periodista, este usuario no es periodista
            // console.log('No es periodista');
            setPeriodista(false);
          }
    }
    const user = supabase.auth.user();
    if(user!==null){
      if(auth.name){
        // dispatch(getUser(user.id))
        traer(user.id);
      }else{
        traer(user.id);
      }
      // dispatch(login(user.id, usuario.nombre));
      // console.log('de Navbar ya hay auth')
    }else{
      // console.log('de Navbar no hay auth')
    }
  
  }, [dispatch,auth.name]);
  if (isMobile) {
    return (
      
        <div style={{backgroundColor:'#081d4b'}}>
          <div
           
            
            // className={classes.appBarMa}
            style={{backgroundColor:'#081d4b',paddingLeft:'15px',paddingRight:'0px',paddingTop:'2px',paddingBottom:'2px',marginLeft:'20px',marginRight:'20px'}}
          >
            {/* <div className={classes.toolbarMa}> */}
              <div style={{width:'100%'}}>
                <MobileMenu auth={auth} active={active} />
              </div>

            {/* </div> */}
          </div>
        </div>
      
    );
  } else {
    return (

        // <div >
        //   <div
            
        //   >
            <div style={{backgroundColor:'#081d4b'}}>
              <div className="top">
                <Link
                  className=""
                  to="/"
                  
                >
                  <SvgLogoOscuro width="120px" height="40px" />
                </Link>
                {
                  auth.name ?
                  (
                <div className="optionsNavbar">
                  <Link to="/notas" className="options-del-nav-bar">
                    {/* <Typography className={classes.navOptionText}> */}
                      Notas
                    {/* </Typography> */}
                  </Link>
                  <Link to="/herramientas" className="options-del-nav-bar">
                    {/* <Typography className={classes.navOptionText}> */}
                      Herramientas
                    {/* </Typography> */}
                  </Link>
                  
                  {/* {
                    periodista && 
                    ( */}
                      <Link to="/newNote" className="options-del-nav-bar">
                    {/* <Typography className={classes.navOptionText}> */}
                    <i className="fa-solid fa-plus"></i> Nueva nota
                    {/* </Typography> */}
                  </Link>
                    {/* )
                  } */}
                  

                  <Link to={`/profile/${auth.uid}`} className="options-del-nav-bar">
                      {
                        active?.fotoUsuario === 'default' ?
                        <i className="fa-solid fa-circle-user"></i> :
                        <img src={auth?.fotoUsuario} alt="" style={{width:'22px',height:'22px',borderRadius:'50%',marginRight:'5px'}} referrerPolicy="no-referrer"/>
                      }
                      &nbsp;
                      {auth?.name}
                  </Link>
                </div>
                  )
                  :
                  (
                <div className="optionsNavbar">
                  <Link to="/notas" className="options-del-nav-bar">
                    {/* <Typography className={classes.navOptionText}> */}
                      Notas
                    {/* </Typography> */}
                  </Link>
                  <Link to="/herramientas" className="options-del-nav-bar">
                    {/* <Typography className={classes.navOptionText}> */}
                      Herramientas
                    {/* </Typography> */}
                  </Link>
                  <Link to="/auth/login" className="options-del-nav-bar" style={{border:'1px solid #fff',borderRadius:'70px'}}>
                    {/* <Typography className={classes.navOptionText}> */}
                      Iniciar sesión
                    {/* </Typography> */}
                  </Link>
                </div>
                  )
                }
                
              </div>
              </div>
        //   </div>
        // </div>
     
    );
  }
};

export default TopNavigation;
