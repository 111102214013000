import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startLoadingNotesProfile,setNotesProfile,setHasMoreProfile,setIndexProfile } from '../redux/actions/profile';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArticlePreview from './ArticlePreview';
import { activeNote } from '../redux/actions/notes';
import ArticleList from './ArticleList';
import { supabase } from '../supabase/client';

const NotesProfile = ({idUser}) => {
  const {notes,hasMore,index} =useSelector(state=>state.profile);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
	// useEffect(() => {
	// 	dispatch(activeNote(null));
  //   dispatch(setNotesProfile([]));
  //   dispatch(setHasMoreProfile(true));
  //   dispatch(setIndexProfile(index*(-1)));
  //   dispatch(startLoadingNotesProfile(idUser));
	// }, [dispatch]);
  useEffect(() => {
    const todos = async() => {
  const { data, error } = await supabase
  .from('notas')
  .select(`
  *,
  usuario:idUsuario (nombre,fotoUsuario)
  `)
  .eq('idUsuario',idUser)
  .order('fechaHora', { ascending: false });
  dispatch(setNotesProfile(data));
  setIsLoading(false);
}
todos();
  // dispatch(startLoadingNotes());
  // dispatch(getUser(idUsuario));
}, [dispatch,idUser]);
  const fetchMoreData = async() => {
		await	dispatch(startLoadingNotesProfile(idUser));
  };
  return (
    <div>
      	{/* <InfiniteScroll
          dataLength={notes.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
					<Box sx={{ display: 'flex',justifyContent:'center' }}>
						<CircularProgress />
					</Box>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Ya no hay mas notas</b>
            </p>
          }
					style={{overflow:'hidden'}}
        >
          
          {
						notes.length===0 ?
						<div className="article-preview">
							No hay notas aún...
						</div> :

						<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
							{
								notes.map((i, index) => (
									// <div style={{height:'30px',margin:'6px',border:'1px solid green', padding:'8px'}} key={index}>
									//   div - #{index}
									// </div>
									<ArticlePreview key={i.idNota} article={i} />
									))
							}
						</div>
					}
        </InfiniteScroll> */}
        
        {
				isLoading ? 
				<Box sx={{ display: 'flex',justifyContent:'center' }}>
				<CircularProgress sx={{color:'var(--primary-color)'}}/>
			</Box> :
				<ArticleList
       articles={notes} 
      />
			}
    </div>
  )
}

export default NotesProfile