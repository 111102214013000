import { Typography } from "@mui/material";
import React from "react";
import CommentForm from "./CommentForm";
import './Comment.css';
const Comment = ({
  comment,
  replies,
  currentUserId,
  deleteComment,
  updateComment,
  activeComment,
  addComment,
  setActiveComment,
  parentId = null,
}) => {
  const fiveMinutes = 300000;
  const timePassed = new Date() - new Date(comment.fechaHora) > fiveMinutes;
  const canReply = Boolean(currentUserId);
  const canEdit = currentUserId === comment.idUsuario && !timePassed;
  const canDelete = currentUserId === comment.idUsuario && !timePassed;
  const createdAt = new Date(comment.fechaHora).toLocaleDateString();
  const isReplying =
    activeComment &&
    activeComment.type === "replying" &&
    activeComment.idComentario === comment.idComentario;
  const isEditing =
    activeComment &&
    activeComment.type === "editing" &&
    activeComment.idComentario === comment.idComentario;
  const replyId = parentId ? parentId : comment.idComentario;
  return (
    <div className="comment">
      <div className="comment-image-container">
        <img
          src={comment.usuario.fotoUsuario==='default'? 'https://res.cloudinary.com/dewxeiala/image/upload/v1658443472/male_user_lb5fld.svg' : comment.usuario.fotoUsuario}
          alt=""
          width="30px"
          height="30px"
        />
      </div>
      <div className="comment-right-part">
        <div className="comment-content">
          <div className="comment-author"><Typography sx={{fontFamily:'Arial'}}>{comment.usuario.nombre}</Typography></div>
          <div><Typography sx={{fontFamily:'Arial'}}>{createdAt}</Typography></div>
        </div>
        {!isEditing && <div className="comment-text"><Typography sx={{fontFamily:'Arial'}}>{comment.contenido}</Typography></div>}
        {isEditing && (
          <CommentForm
            submitLabel="Actualizar"
            hasCancelButton
            initialText={comment.contenido}
            handleSubmit={(text) => updateComment(text, comment.idComentario)}
            handleCancel={()=>setActiveComment(null)}
          />
        )}
        <div className="comment-actions">
          {canReply && (
            <div
              className="comment-action"
              onClick={() =>
                setActiveComment({ idComentario: comment.idComentario, type: "replying" })
              }
            >
              Responder
            </div>
          )}
          {canEdit && (
            <div
              className="comment-action"
              onClick={() =>
                setActiveComment({ idComentario: comment.idComentario, type: "editing" })
              }
            >
              Editar
            </div>
          )}
          {canDelete && (
            <div
              className="comment-action"
              onClick={() => deleteComment(comment.idComentario)}
            >
              Borrar
            </div>
          )}
        </div>
        {isReplying && (
          <CommentForm
            submitLabel="Responder"
            handleSubmit={(text) => addComment(text, replyId)}
          />
        )}
        {replies.length > 0 && (
          <div className="replies">
            {replies.map((reply) => (
              <Comment
                key={reply.idComentario}
                comment={reply}
                replies={[]}
                currentUserId={currentUserId}
                deleteComment={deleteComment}
                updateComment={updateComment}
                addComment={addComment}
                activeComment={activeComment}
                setActiveComment={setActiveComment}
                parentId={comment.idComentario}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Comment;
