import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Footer from "./Footer";
import TopNavigation from "./TopNavigation";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';


const Documentos = () => {

  return (
    <div>
      <TopNavigation />
      <Container maxWidth="lg" align="center">
        <Typography variant="h4" sx={{marginTop: '20px'}}>Documentos relevantes</Typography>
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

        <List>
          <ListItem disablePadding>
            <ListItemButton component="a" href="https://www.sicoes.gob.bo/portal/normativa/decretos.php" target="_blank">
              <ListItemText primary="Normativa del SICOES" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="https://www.unodc.org/documents/bolivia/Guia_Contratacion_Publica_a_nivel_Municipal.pdf" target="_blank">
              <ListItemText primary="Guía de Procesos de Contratación Pública a nivel Municipal" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="a" href="https://standard.open-contracting.org/latest/es/" target="_blank">
              <ListItemText primary="Estándar de Datos para las Contrataciones Abiertas" />
            </ListItemButton>
          </ListItem>
        </List>
        </Box>

      </Container>
      <div style={{ marginTop: "250px" }}></div>
      <Footer />
    </div>
  );
};

export default Documentos;
