import { types } from "../../types/types"

const initialState = {
  notes:[],
  active:null,
  index:0,
  hasMore: true
}
export const notesReducer = (state=initialState,action) => {
  switch (action.type) {
    case types.notesActive:
      return {
        ...state,
        active:{
          ...action.payload
        }
      }
    case types.notesAddNew:
      return {
        ...state,
        notes: [action.payload,...state.notes]
      }
    case types.notesLoad:
      return {
        ...state,
        notes:[...action.payload]
      }
    case types.notesUpdated:
      return {
        ...state,
        notes:state.notes.map(
          note=>note.id===action.payload.id
          ? action.payload.note
          : note
        )
      }
    case types.notesDelete:
      return {
        ...state,
        active:null,
        notes:state.notes.filter(note=>note.id!==action.payload)
      }
    case types.notesLogoutCleaning:
      return {
        ...state,
        active:null,
        notes:[]
        }
    case types.notesSetIndex:
      return {
        ...state,
        index:action.payload
      }
    case types.notesSetHasMore:
      return {
        ...state,
        hasMore: action.payload
      }
    case types.notesResetIndex:
    return {
      index: 0,
      hasMore:true,
      notes:[],
      active:null
    }
    // case types.notesLoadSingle:
    //   return {
    //     ...state,

    //   }
    default:
      return state;
  }
}