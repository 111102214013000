import React from 'react'
import './stylesHome.css';
import logo from '../assets/logo-mitad.png'
import { Box } from '@mui/system';
import { Container } from '@mui/material';
import { ReactComponent as LogoSVG } from '../assets/logo-animado-x2.svg';
import Image from 'mui-image'
import Footer from './Footer';
import TopNavigation from './TopNavigation';
import CovidPage from './pages/covid/CovidPage';
import OtherPage from './pages/other/OtherPage';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const Home = () => {
  const [tab, setTab] = React.useState('1');

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      {/* <Navbar />   */}
      <TopNavigation />
      {/* <MaterialUIDrawer /> */}
      <div className='banner'>
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LogoSVG />
            <Image src={logo} duration={5000} />
          </Box>
          {/* <h2 className='font-face-cg'>Un lugar de transparencia</h2> */}
        </Container>

      </div>
      
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} centered>
            <Tab label="Covid-19" value="1" />
            <Tab label="Otros temas" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <CovidPage/>
          </TabPanel>
        <TabPanel value="2">
          <OtherPage/>
          </TabPanel>
      </TabContext>
      </Box>

      
      <Footer />
    </>

  )
}

export default Home;