import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = ({
  isAuthenticated, isPeriodista
}) => {
 
  return (
    
        
          (isAuthenticated && isPeriodista)
          ? (<Outlet />)
          : (<Navigate to="/auth/login" />)
        
    
  )
}

export default PrivateRoute
