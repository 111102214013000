import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Footer from "./Footer";
import TopNavigation from "./TopNavigation";
import DescriptionIcon from "@mui/icons-material/Description";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";

const Proyecto = () => {
  return (
    <div>
      <TopNavigation />
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ marginTop: "46px", fontWeight:"bold ", color:"#081d4b" }}>
          Sobre el proyecto
        </Typography>
        <Typography variant="body1" align="justify" sx={{ marginTop: "12px" }}>
          Contratos Abiertos es un proyecto creado por el Laboratorio de
          Tecnologías Sociales con el objetivo de mejorar la transparencia y
          accesibilidad de las contrataciones estatales en Bolivia. El proyecto
          se enfoca en proporcionar información clara y procesable sobre los
          contratos estatales, permitiendo que cualquier ciudadano pueda conocer
          y monitorear el uso de los recursos públicos.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ marginTop: "12px" }}>
          Para alcanzar este objetivo, Contratos Abiertos cuenta con una
          plataforma en línea que incluye varias herramientas y recursos. En
          primer lugar, la plataforma ofrece una lista completa y actualizada de
          contratos estatales desde 2012 hasta 2022, disponible para su descarga
          y análisis. Además, cuenta con herramientas para extraer y procesar
          datos de SICOES, el sistema de información de contrataciones estatales
          en Bolivia, y también proporciona datos específicos sobre los
          contratos de Covid-19 en Bolivia desde 2020 hasta 2022.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ marginTop: "12px" }}>
          Además de estos recursos, Contratos Abiertos incluye una serie de
          artículos de periodistas y dateros analizando, visualizando y dando
          contexto a los datos de contrataciones, así como una serie de listas y
          espacios de discusión que permiten a los ciudadanos interesados
          conectarse y compartir información. Esto incluye una lista de
          convocatorias y concursos relevantes en el país, así como un espacio
          para compartir información sobre contratos específicos.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ marginTop: "12px" }}>
          Los ciudadanos de Bolivia, incluyendo periodistas, investigadores,
          activistas, programadores y dateros, están invitados a participar en
          el proyecto. Los participantes pueden descargar y analizar los datos
          disponibles, extraer datos de SICOES utilizando nuestras herramientas,
          compartir información relevante sobre contratos estatales y
          registrarse para compartir datos y enlaces o para publicar notas
          basadas en los datos de contrataciones estatales.
        </Typography>
        <Typography variant="body1" align="justify" sx={{ marginTop: "12px" }}>
          Al inscribirte con tu cuenta de correo de Google, podrás tener un
          perfil y comentar en la plataforma. Si deseas publicar notas,
          simplemente rellena este formulario. ¡Únete a nosotros para mejorar la
          transparencia gubernamental en las contrataciones estatales en
          Bolivia!
        </Typography>
        <Link
          sx={{
            textDecoration: "none",
            marginTop: "20px",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "3px",
            marginTop:"30px"
          }}
          href="https://forms.gle/73mXpySv7BGURNLAA"
        >
          <Button
            variant="outlined"
            size="large"
            startIcon={<DescriptionIcon />}
          >
            Formulario
          </Button>
        </Link>
      </Container>
      <div style={{ marginTop: "120px" }}></div>
      <Footer />
    </div>
  );
};

export default Proyecto;
