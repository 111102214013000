import { db,firebase } from "../../firebase/firebase-config";
import { fileUpload } from "../../helpers/fileUpload";
// import { fileUpload } from "../helpers/fileUpload";
import { loadNotes } from "../../helpers/loadNotes";
import { loadNotesByTag } from "../../helpers/loadNotesByTag";
import { supabase } from "../../supabase/client";
import { types } from "../../types/types";

export const startNewNote=(note) => {
  return async(dispatch, getState) => {
    const {uid, name} = getState().auth;
    // console.log(uid)
    const newNote = {
      titulo:note.titulo,
      contenido:note.contenido,
      idUsuario:uid,
      autor:name,
      imgPrincipal:note.imgPrincipal,
      nFavs:0,
      nComments:0,
      tags: note.tags,
      link:note.link
    }
    //  const doc = await db.collection('notas').add(newNote);
    const { data } = await supabase
    .from('notas')
    .insert([
       newNote
    ])
    .single()

    // dispatch(activeNote(data.idNota,newNote));
    // console.log(data);
    // dispatch(addNewNote(doc.id,newNote));
  }
}
export const activeNote = (note) => {
  return {
    type: types.notesActive,
    payload:{...note}
  }
}

export const addNewNote = (id,note) => {
  return {
    type:types.notesAddNew,
    payload: {
      id,
      ...note
    }
  }
}
export const startLoadingNotes=() => {
  return async(dispatch,getState) => {
    const {index,notes:n} = getState().notes;
      // console.log(`Trayendo datos
      // , index>${index}, 
      // notes>${n}`)
    const notes = await loadNotes(index);
    // console.log(`Desques de traer datos
    // , index>${index}, 
    // notes>${notes}`)
    if(notes.length>0){
      dispatch(setNotes([...n,...notes]));
      dispatch(setIndex(index+3));
      // console.log('se modifico el index')
    }else{
      // console.log('HAAAAAAAAAAAAAAAAAAAAAAAAAAAAA')
      // dispatch(setNotes([...n,...notes]));
      // dispatch(setIndex(index+3));
      // dispatch(setHasMore(false))
    }
    
    
  }
}
export const startLoadingNotesWithTag =(tag) => {
  return async(dispatch,getState) => {
    const {index,notes:n} = getState().notes;
    const notes = await loadNotesByTag(index,tag);
    if(notes.length>0){
      dispatch(setNotes([...n,...notes]));
      dispatch(setIndex(3));
      // console.log('Si results')
    }else{
      // console.log('No results');
      dispatch(setNotes([...n,...notes]));
      dispatch(setIndex(3));
      dispatch(setHasMore(false))
    }
  }
}
export const setNotes = (notes) => {
  return {
    type: types.notesLoad,
    payload:notes
  }
}
export const startUpdateNote = (note) => {
  return async (dispatch,getState) => {
    const { data, error } = await supabase.from('notas')
    .update({ titulo: note.titulo,
              imgPrincipal:note.imgPrincipal,
              tags:note.tags,
              contenido:note.contenido,
              link:note.link
             })
    .match({ idNota: note.idNota })
    // console.log(data);
    // console.log(error);
    // console.log('Saved ',note.titulo, ' success');
  }
}

export const refreshNote = (id,note) => {
  return {type:types.notesUpdated,
  payload:{
    id,
    note:{
      id,
      ...note
    }
  }}
}

export const startUploading = (file,note) => {
  return async (dispatch,getState) => {
    // const {active:activeNote} = getState().notes;
    // Swal.fire({
    //   title:'Uploading',
    //   text:'Please wait...',
    //   allowOutsideClick:false,
    //   showConfirmButton:false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   }
    // })
    // console.log('Uploading Image');
    const fileUrl = await fileUpload(file);
    // console.log("URL FILE","->",fileUrl);
    note.imgPrincipal= await fileUrl;
    dispatch(startNewNote(note));
    // activeNote.imagen = 'default';
    // dispatch(startSaveNote(activeNote));
    // Swal.close();
  }
}
export const startUploadingFromEdit = (file,note) => {
  return async (dispatch,getState) => {
    // console.log('Uploading Image');
    const fileUrl = await fileUpload(file);
    // console.log("URL FILE","->",fileUrl);
    note.imgPrincipal= await fileUrl;
    dispatch(startUpdateNote(note));
  }
} 
export const startDeleting = (id) => {
  return async (dispatch,getState) => {
    const uid = getState().auth.uid;
    await db.collection('notas').doc(id).delete();
    dispatch(deleteNote(id));
  }
}

export const deleteNote = (id) => {
  return {
    type:types.notesDelete,
    payload:id
  }
}
export const noteLogout=() => {
  return {
    type:types.notesLogoutCleaning
  }
}
export const setIndex=(value) => {
  return {
    type:types.notesSetIndex,
    payload: value
  }
}
export const setHasMore=(value) => {
  return {
    type:types.notesSetHasMore,
    payload:value
  }
}
export const resetIndexNotes=() => {
  return {
    type:types.notesResetIndex
  }
}