import React, { useEffect, useState } from "react";
import Comment from "./Comment";
import CommentForm from "./CommentForm";
import "./NoteComments.css";
import {useSelector} from 'react-redux';
import { supabase } from "../supabase/client";
import { useParams } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
// const comments = [
//   {
//     id: "1",
//     body: "First comment",
//     username: "Jack",
//     userId: "1",
//     parentId: null,
//     createdAt: "2021-08-16T23:00:33.010+02:00",
//   },
//   {
//     id: "2",
//     body: "Second comment",
//     username: "John",
//     userId: "2",
//     parentId: null,
//     createdAt: "2021-08-16T23:00:33.010+02:00",
//   },
//   {
//     id: "3",
//     body: "First comment first child",
//     username: "John",
//     userId: "2",
//     parentId: "1",
//     createdAt: "2021-08-16T23:00:33.010+02:00",
//   },
//   {
//     id: "4",
//     body: "Second comment second child",
//     username: "John",
//     userId: "2",
//     parentId: "2",
//     createdAt: "2021-08-16T23:00:33.010+02:00",
//   },
// ];
// const currentUserId = "1";
const NoteComments = () => {
  const [open, setOpen] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const {idNota} = useParams();
  const{auth} = useSelector(state=>state);
  const [currentUserId, setCurrentUserId] = useState(auth?.uid);
  const [backendComments, setBackendComments] = useState([]);
  const [activeComment, setActiveComment] = useState(null);

  const rootComments = backendComments.filter(
    (backendComment) => backendComment?.parentId === null
  );
  const getReplies = (commentId) => {
    return backendComments
      .filter((backendComment) => backendComment?.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a?.fechaHora).getTime() - new Date(b?.fechaHora).getTime()
      );
  };
   const createComment = async (text, parentId = null) => {
    const newComment = {
      idUsuario: auth?.uid,
      idNota:idNota,
      contenido:text,
      parentId:parentId
    };
    const { data,error } = await supabase
    .from('comentarios')
    .insert([
       newComment
    ])
    .single();
    if (parentId===null) {
      // console.log(data);
      data.usuario = {nombre:auth?.name,fotoUsuario:auth?.fotoUsuario}
      return data;   
    }else{
      // console.log(data);
      data.usuario = {nombre:auth?.name,fotoUsuario:auth?.fotoUsuario}
      return data; 
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
const addComment = (text,parentId) => {
  // console.log("addComment",text, parentId);
  createComment(text,parentId).then(comment=>{
    setBackendComments([comment,...backendComments])
    setActiveComment(null)
  })
}
const removeComment = (commentId) => {
  if (window.confirm('Estas seguro de eliminar el comentario')) {
    deleteComment(commentId).then(()=>{
      const updatedBackendComments = backendComments.filter(
        (backendComment)=> backendComment.idComentario!==commentId
      );
      setBackendComments(updatedBackendComments);
    })
  }
}
const updateCommentF = (text,commentId) => {
  updateComment(text,commentId).then(()=>{
    const updatedBackendComments = backendComments.map(backendComment=>{
      if (backendComment.idComentario===commentId) {
        return {...backendComment,contenido:text};
      }
      return backendComment;
    });
    setBackendComments(updatedBackendComments);
    setActiveComment(null);
  });
};
const updateComment = async (text,commentId) => {
  const { data, error } = await supabase.from('comentarios')
  .update({
           contenido:text 
           })
  .match({ idComentario: commentId})
  // console.log(data);
  return { text };
};

const deleteComment = async (commentId) => {
  const { data, error } = await supabase
  .from('comentarios')
  .delete()
  .match({ idComentario: commentId });
  return {};
};
  useEffect(() => {
    const getComments = async(idNote) => {
      const {data,error} = await supabase.from('comentarios').select(`
      *,
      usuario:idUsuario (nombre,fotoUsuario)
      `).eq('idNota',idNote);
      if (data.length===0) {
        // console.log('No se encontraron comentarios');
        // setChecking(false);
      }else{
        // setNota(data[0]);
        // dispatch(activeNote(data[0]));
        // setChecking(false);
        setBackendComments(data);
      }
      
    }
   getComments(idNota);
  }, [idNota]);
  return (
    <div className="comments">
      <h3 className="comments-title font-face-cg">Comentarios</h3>
      <div className="comment-form-title font-face-cg">Escribe un comentario</div>
      <CommentForm submitLabel="Publicar" handleSubmit={addComment} />
      <div className="comments-container">
        {rootComments.map((rootComment) => (
          // <div key={rootComment.id}>{rootComment.body}</div>
          <Comment key={rootComment.idComentario} comment={rootComment} replies={getReplies(rootComment.idComentario)}
          currentUserId={auth?.uid}
          deleteComment={removeComment}
          updateComment={updateCommentF}
          activeComment={activeComment}
          setActiveComment={setActiveComment}
          addComment={addComment}
          />

        ))}
      </div>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className='font-face-cg' sx={{fontFamily:'CenturyGothic'}} fontSize="18px">
          {"¿Está seguro de eliminar esta nota?"}
        </DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description" fontSize="14px" sx={{fontFamily:'Arial'}} >
            Al eliminar esta nota se eliminará completamente y los enlaces a esta nota ya no funcionarán
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{fontSize:'12x',fontFamily:'CenturyGothic'}} disabled={buttonDisabled}>Cancelar</Button>
          <Button onClick={handleDelete} autoFocus sx={{fontSize:'12px',fontFamily:'CenturyGothic'}} disabled={buttonDisabled}>
            Si, eliminar
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default NoteComments;
