import Graficos from './Graficos';
import Tablas from './Tablas';
import { Typography, Box, Button } from '@mui/material';

const CovidPage = () => {
    return (
        <>
            <Graficos />
            <Tablas />
            <Typography variant="h5" gutterBottom align="center" padding="20px"sx={{fontWeight:"bold ", color:"#081d4b" }}>
                Todas las fichas
            </Typography>
            <Box textAlign='center'>
                <Button variant="outlined" href="https://drive.google.com/drive/folders/1QtWj4fXusO90NdcG1khyfh0x5Oqq9zlV?usp=sharing" target="_blank" sx={{ marginBottom: 2, color: '#081D4B' }} >Ver fichas</Button>
            </Box>
        </>
    )
}

export default CovidPage;


