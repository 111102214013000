import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startLogout } from "../redux/actions/auth";
import "./auth/styles.css";
import Navbar from "./Navbar";
// import "./medium.css";
import "./Home.css";
import MainView from "./MainView";
import Banner from "./Banner";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { supabase } from "../supabase/client";
import { setNotes, startLoadingNotesWithTag } from "../redux/actions/notes";
import FilteredNotes from "./FilteredNotes";
import "./HomePrivate.css";
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";
import Footer from "./Footer";
import MaterialUIDrawer from "./MaterialUIDrawer";
import { styled } from '@mui/material/styles';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TopNavigation from "./TopNavigation";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const tags = [
  "todos",
  "implementations",
  "welcome",
  "introduction",
  "codebaseShow",
  "nati",
  "Bosques",
];

const HomePrivate = () => {
  // const [elementos, setElementos] = useState([]);
  // const [indexStart, setIndexStart] = useState(0);
  // const [indexEnd, setIndexEnd] = useState(4);
  const dispatch = useDispatch();
  const { notes } = useSelector((state) => state.notes);
  const [stateTag, setStateTag] = useState("todos");
  const [notasFiltradas, setNotasFiltradas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTags, setIsLoadingTags] = useState(true);
  const [tagsList, setTagsList] = useState([]);
  const handleClick = (t) => {
    // console.info('You clicked the Chip.',t);
    // dispatch(startLoadingNotesWithTag(t));
    if (t === stateTag) {
      // console.log('No hacer nada');
    } else {
      if (t === "todos") {
        setStateTag("todos");
      } else {
        setStateTag(t);
      }
      tagsList.forEach((element) => {
        if (t === element) {
          document.getElementById(element + `-tag`).style.backgroundColor =
            "var(--primary-color)";
          document.getElementById(element + `-tag`).style.color = "#fff";
          // console.log(element+`-tag`);
        } else {
          document.getElementById(element + `-tag`).style.backgroundColor =
            "#fff";
          document.getElementById(element + `-tag`).style.color =
            "var(--primary-color)";
          // console.log(t+`-tag`);
        }
      });
    }
  };

  const getDatos = async () => {
    // usuarios!inner(*)
    const { data, error } = await supabase
      .from("notas")
      .select(
        `
    *,
     usuario:idUsuario (nombre,fotoUsuario)
  `
      )
      .order("fechaHora", { ascending: false })
      .range(1, 20);
    // console.log(data);
    // console.log(error)
  };

  // const fetchMoreData = () => {
  //   let data = [];

  //   setTimeout(() => {
  //     if(indexStart<NOTAS.length){
  //         let count = 1;
  //         for (let index = indexStart; index < indexEnd; index++) {
  //           if(index<NOTAS.length){
  //              data.push(NOTAS[index]);
  //             count=count+1;
  //           }
  //         }
  //         setElementos([...elementos,...data]);
  //         setIndexStart((v)=> v + count);
  //         setIndexEnd((v)=>v+count);
  //       }
  //   }, 500);
  // }
  useEffect(() => {
    // setIsLoading(true)
    //  getDatos();
    //  console.log(NOTAS.length);
    //  fetchMoreData();
    const filtrar = async (tag) => {
      const { data, error } = await supabase
        .from("notas")
        .select(
          `
        *,
        usuario:idUsuario (nombre,fotoUsuario)
        `
        )
        .contains("tags", [tag])
        .order("fechaHora", { ascending: false });
      dispatch(setNotasFiltradas(data));
      setIsLoading(false);
    };
    if (stateTag !== "todos") {
      filtrar(stateTag);
    } else {
      setIsLoading(false);
    }
  }, [stateTag, dispatch]);
  useEffect(() => {
    const traerTags = async () => {
      const { data, error } = await supabase
        .from("notas")
        .select(
          `
      tags
      `
        )
        .order("fechaHora", { ascending: false });

      // console.log(data);
      const tagsResult = [];
      data.forEach((nota) => {
        if (nota.tags.length > 0) {
          const list = nota.tags;
          for (let index = 0; index < list.length; index++) {
            const element = list[index];
            if (!tagsResult.includes(element)) {
              if (element !== "" && element !== null) {
                tagsResult.push(element);
              }
            }
          }
        }
      });
      setTagsList(["todos", ...tagsResult]);
      setIsLoadingTags(false);
    };
    traerTags();
  }, []);
  return (
    <div>
      <TopNavigation />
      {/* <Navbar /> */}
      {/* <MaterialUIDrawer /> */}
      <div className="home-page" >
        <div className="container page" style={{margin:'10px'}}>
        <Grid container spacing={2} >
  <Grid item xs={12} sm={12} md={8}>
  {stateTag === "todos" ? (
              <MainView />
            ) : (
              <FilteredNotes
                key={stateTag}
                notasFiltradas={notasFiltradas}
                isLoading={isLoading}
              />
            )}
  </Grid>
  <Grid item xs={12} sm={12} md={4}>
  <div className="col-md-3">
              <div className="sidebar">
                <p className="font-face-cg" style={{ fontSize: "1.2rem" }}>
                  Etiquetas populares
                </p>{" "}
                <div className="tag-list">
                  {isLoadingTags ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress
                        sx={{ color: "var(--primary-color)" }}
                      />
                    </Box>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginBottom: "15px",
                        fontFamily:'Arial !important',
                      }}
                    >
                      {tagsList.map((tag) =>
                        tag === "todos" && "todos" === stateTag ? (
                          <Chip
                            key={tag}
                            id={tag + `-tag`}
                            className="font-face-arial chip-tag"
                            style={{ fontSize: "0.9rem" }}
                            label={tag}
                            variant="outlined"
                            onClick={() => handleClick(tag)}
                            sx={{
                              backgroundColor: "var(--primary-color)",
                              color: "#fff",
                            }}
                          />
                        ) : (
                          <Chip
                            key={tag}
                            id={tag + `-tag`}
                            className="font-face-arial chip-tag"
                            style={{ fontSize: "0.9rem" }}
                            label={tag}
                            variant="outlined"
                            onClick={() => handleClick(tag)}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
  </Grid>

</Grid>
          {/* <div className="row" style={{ marginTop: "60px" }}>
            {stateTag === "todos" ? (
              <MainView />
            ) : (
              <FilteredNotes
                key={stateTag}
                notasFiltradas={notasFiltradas}
                isLoading={isLoading}
              />
            )}

            <div className="col-md-3">
              <div className="sidebar">
                <p className="font-face-cg" style={{ fontSize: "1.6rem" }}>
                  Etiquetas populares
                </p>{" "}
                <div className="tag-list">
                  {isLoadingTags ? (
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <CircularProgress
                        sx={{ color: "var(--primary-color)" }}
                      />
                    </Box>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                        marginBottom: "15px",
                      }}
                    >
                      {tagsList.map((tag) =>
                        tag === "todos" && "todos" === stateTag ? (
                          <Chip
                            key={tag}
                            id={tag + `-tag`}
                            className="font-face-arial"
                            style={{ fontSize: "1rem" }}
                            label={tag}
                            variant="outlined"
                            onClick={() => handleClick(tag)}
                            sx={{
                              backgroundColor: "var(--primary-color)",
                              color: "#fff",
                            }}
                          />
                        ) : (
                          <Chip
                            key={tag}
                            id={tag + `-tag`}
                            className="font-face-arial"
                            style={{ fontSize: "1rem" }}
                            label={tag}
                            variant="outlined"
                            onClick={() => handleClick(tag)}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePrivate;
