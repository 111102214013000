import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { BarChart, Bar } from "recharts";
import tiempoItems from "../../../data/items/tiempo_items.json";
import DepartamentoItems from "../../../data/items/departamento_items.json";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const Graficos = () => {
  return (
    <div>
      <Typography
        variant="h4"
        align="center"
        padding="20px"
        sx={{ fontWeight: "bold ", color: "#081d4b" }}
      >
        Items
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {" "}
        (de Enero de 2012 a Diciembre de 2021)
      </Typography>

      <Grid container item spacing={2} display="flex" align="center">
        <Grid item xs={12} width={500}>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold ", color: "#081d4b" }}
          >
            Cantidad de Items
          </Typography>
          <ResponsiveContainer width="70%" height={300}>
            <LineChart
              data={tiempoItems}
              fontFamily={"Roboto, sans-serif"}
              margin={{
                top: 20,
                right: 30,
                left: 30,
                bottom: 5,
              }}
            >
              <Line
                type="monotone"
                dataKey="n_conv"
                stroke="#081D4B"
                strokeWidth={4}
                animationBegin={1000}
                animationDuration={3000}
              />
              <CartesianGrid stroke="#ccc" strokeDasharray="6 6" />
              <XAxis dataKey="mes" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <Typography
            variant="subtitle1"
            gutterBottom
            align="center"
            sx={{ fontWeight: "bold ", color: "#081d4b" }}
          >
            Departamento de Items
          </Typography>
          <ResponsiveContainer width="70%" height={270}>
            <BarChart
              data={DepartamentoItems}
              fontFamily={"Roboto, sans-serif"}
              margin={{
                top: 20,
                right: 30,
                left: 30,
                bottom: 5,
              }}
            >
              <XAxis dataKey="departamento" />
              <YAxis />
              <Bar
                dataKey="n"
                barSize={70}
                fill="#081D4B"
                animationBegin={2000}
                animationDuration={3000}
              />
              <Tooltip />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </div>
  );
};

export default Graficos;
