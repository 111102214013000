
import { store } from './redux/store/store';
import { Provider } from 'react-redux';
import './App.css';
import AppRouter from "./routers/AppRouter";
import { createTheme, ThemeProvider } from '@mui/material';

function App() {
  const theme = createTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#081d4b',
      },
      secondary: {
        main: '#103a98',
      },
      typography: {
        fontFamily: 'Arial',
        h2: {
          fontFamily: 'CenturyGothic',
        },
      },
    },
  });
  return (
  <Provider store={store}>
    <ThemeProvider theme={theme}>

    <AppRouter />
    </ThemeProvider>
    
  </Provider>

  );
}

export default App;
