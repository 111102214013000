import MaterialReactTable from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { esES } from '@mui/material/locale';
import { tablaES } from '../../../data/tabla_es';


export default function Tabla({ columns, data, title, path }) {
    const theme = createTheme({
        palette: {
            primary: {
                main: '#081D4B',
            }
        },
    }, esES);
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };
    return (
        <ThemeProvider theme={theme}>
            <Typography variant="h6" gutterBottom align="center" padding="20px" sx={{fontWeight:"bold ", color:"#081d4b" }}>
                {title}
            </Typography>
            <MaterialReactTable
                columns={columns}
                data={data}
                localization={tablaES}
                enableRowActions
                initialState={{density: 'compact'}}
                renderRowActions={({ row }) => (
                    <div>
                      <Button 
                      href={'fichas/' + row.original.cuce + '.pdf'} 
                      target="_blank" 
                      size='small'
                      sx={{textTransform: 'lowercase'}}
                      >Ver ficha</Button>
                    </div>
                  )}
                renderToolbarTopCustomActions={({ table }) => (
                    <Button
                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                        onClick={() =>
                            handleExportRows(table.getPrePaginationRowModel().rows)
                        }
                        startIcon={<FileDownloadIcon />}
                        variant="outlined"
                    >
                        Descargar resultados
                    </Button>
                )}

            />
            <Box sx={{ textAlign: 'right' }}>
                <Typography variant="caption" gutterBottom align="right" padding="20px">
                    Recopilación: <a href="https://github.com/lab-tecnosocial">Lab TecnoSocial</a>
                </Typography>
            </Box>
            <Box textAlign='center'>
                <Button variant="outlined" href={path} download sx={{ margin: 2}} >Descargar todo</Button>
            </Box>
            
        </ThemeProvider>
    )
}
