import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
import SvgLogoOscuro from "./SvgLogoOscuro";

const useStyles = makeStyles({
  menu: {
    
    "& .MuiMenu-paper": {
      backgroundColor: "#F7F7F7",
    },
    "& :hover": {
      textDecoration:"none"
      }
  },
  link: {
    color:'#081d4b',
    fontFamily:'Arial',
    
    textDecoration: "none",
    "& :hover": {
    textDecoration:"none"
    }
  },
});

const MobileMenu = ({auth={},active,periodista}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div style={{display:'flex', justifyContent:'space-between',width:'100%'}}>
      <Link to="/" style={{marginTop:'auto',marginBottom:'auto'}}>
          {/* <img src={logo} alt="" width="120px" /> */}
          <SvgLogoOscuro width="110px"  />
          </Link>
      <IconButton edge="start" color="inherit" onClick={handleMenu} >
        <MenuIcon style={{ width: '30px', height: '30px',color:'#fff' }} />
      </IconButton>

      
        {/* Opciones de menu desplegable */}
        {
          auth.name ?
          (
            <Menu
        id="mobile-menu-appbar"
        className={classes.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
            <Link to="/notas" className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>Notas</MenuItem>
        </Link>
        <Link to="/herramientas" className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>Herramientas</MenuItem>
        </Link>
        {/* {
          periodista && ( */}
            <Link to="/newNote" className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}><i className="fa-solid fa-plus"></i> Nueva nota</MenuItem>
        </Link>
          {/* )
        } */}
        <Link to={`/profile/${auth.uid}`} className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>
          {
                        active?.fotoUsuario === 'default' ?
                        <i className="fa-solid fa-circle-user"></i> :
                        <img src={auth?.fotoUsuario} alt="" style={{width:'22px',height:'22px',borderRadius:'50%',marginRight:'5px'}} referrerPolicy="no-referrer"/>
                      }
                      &nbsp;
                      {auth?.name}
          </MenuItem>
        </Link>
           </Menu>
          )
          :
          (
            <Menu
        id="mobile-menu-appbar"
        className={classes.menu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
            <Link to="/notas" className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>Notas</MenuItem>
        </Link>
        <Link to="/herramientas" className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>Herramientas</MenuItem>
        </Link>
        <Link to="/auth/login" className={classes.link}>
          <MenuItem className={classes.menuItem} onClick={handleClose}>Iniciar sesión</MenuItem>
        </Link>
        </Menu>
          )
        }
        

     
    </div>
  );
};

export default MobileMenu;
