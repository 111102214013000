import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setHasMoreStarProfile, setIndexStarProfile, setNotesStarProfile, startLoadingNotesStarProfile } from '../redux/actions/profile';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InfiniteScroll from 'react-infinite-scroll-component';
import ArticlePreview from './ArticlePreview';
import { activeNote } from '../redux/actions/notes';
import { supabase } from '../supabase/client';
import ArticleList from './ArticleList';
import ArticleListStar from './ArticleListStar';

const NotesStarProfile = ({idUser}) => {
  const {notesStar,hasMoreStar,indexStar} =useSelector(state=>state.profile);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
	// useEffect(() => {
	// 	dispatch(activeNote(null));
  //   dispatch(setNotesStarProfile([]));
  //   dispatch(setHasMoreStarProfile(true));
  //   dispatch(setIndexStarProfile(indexStar*(-1)));
  //   dispatch(startLoadingNotesStarProfile(idUser));
	// }, [dispatch]);
  useEffect(() => {
    const todos = async() => {
  const { data, error } = await supabase
  .from('favs')
  .select(`
    nota:idNota (*,
      usuario:idUsuario (nombre,fotoUsuario))
  `)
  .eq('idUsuario',idUser)
  .order('fechaHora', { ascending: false })
  
  dispatch(setNotesStarProfile(data));
  setIsLoading(false);
}
todos();
  // dispatch(startLoadingNotes());
  // dispatch(getUser(idUsuario));
}, [dispatch,idUser]);
  const fetchMoreData = async() => {
		await	dispatch(startLoadingNotesStarProfile(idUser));
  };
  return (
    <div>
      	{/* <InfiniteScroll
          dataLength={notesStar.length}
          next={fetchMoreData}
          hasMore={hasMoreStar}
          loader={
					<Box sx={{ display: 'flex',justifyContent:'center' }}>
						<CircularProgress />
					</Box>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Ya no hay mas notas</b>
            </p>
          }
					style={{overflow:'hidden'}}
        >
          
          {
						notesStar.length===0 ?
						<div className="article-preview">
							No hay notas aún...
						</div> :

						<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
							{
								notesStar.map((i, index) => (
									// <div style={{height:'30px',margin:'6px',border:'1px solid green', padding:'8px'}} key={index}>
									//   div - #{index}
									// </div>
									<ArticlePreview key={i.nota.idNota+`Star`} article={i.nota} />
									))
							}
						</div>
					}
        </InfiniteScroll> */}
         {
				isLoading ? 
				<Box sx={{ display: 'flex',justifyContent:'center' }}>
				<CircularProgress sx={{color:'var(--primary-color)'}}/>
			</Box> :
				<ArticleListStar
       articles={notesStar} 
      />
			}
    </div>
  )
}

export default NotesStarProfile

